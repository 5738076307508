<!--<app-header></app-header>-->
<!-- <form> -->
<div class="form-container">
<!--    <div class="background"></div>-->
    <a class="skip" (click)="gotoMain()"> I’ll do it later</a>
    <div class="form-wrap">
        <perfect-scrollbar>
            <div class="form-fields-wrap">
                <h3 class="title">Tell us more about your brand and help us create designs you’ll love, faster</h3>

                <div class="form-field logo-field">
                    <ngx-file-drop (onFileDrop)="dropped($event,'logo')" dropZoneClassName="dropZoneClass"
                        (onFileOver)="fileOver($event,'logo')" (onFileLeave)="fileLeave($event)">
                        <ng-template ngx-file-drop-content-tmp *ngIf="showFileOverlayLogo">
                            <span>
                                <img src="../../../assets/images/upload-white.png">
                            </span>
                        </ng-template>
                    </ngx-file-drop>
                    <h3 class="field-title">Add your logo</h3>
                    <p class="field-desc">Upload your brand logo and we’ll use it as your brand identity. Attach
                        your logo or if you don’t have it, write your brand name and we’ll use that for now.</p>

                    <!--Uploading loader start here-->
                    <div class="fileUploading" *ngIf="logoLoader">
                        <p>{{currentFileNameLogo}}</p>
                        <div class="loaderFileName">
                            <div class="fileUploadingLoader"></div>
                        </div>
                    </div>
                    <!--Uploading loader end here-->

                    <button *ngIf="!logoLoader" class="form-btn field-dotted-btn">Upload Logo
                        <input class="form-inupt-overlay" multiple id="uploadImg" accept=".png,.jpg,.jpeg,.gif"
                            (change)="uploadFile($event,'logo')" type="file">
                    </button>
                    <div *ngIf="!logoLoader" class="uploaded-logo-wrap">
                        <div class="uploaded-logo" *ngFor="let fileArray of formModel.logo_attachments?.files">
                            <span class="name">{{fileArray?.name}}</span>
                            <span class="remove icon-crossnew" (click)="remove(fileArray,'logo')"></span>
                        </div>
                    </div>

                    <p *ngIf="!brandTextArea && !logoLoader"  class="comment-txt">Or you can <span (click)="changeState('brandTextArea')"
                            class="highlight-color">Write brand
                            name or Comment</span></p>
                    <textarea *ngIf="brandTextArea && !logoLoader" [(ngModel)]="formModel.logo_attachments.reference"
                        (ngModelChange)="enableButton()" class="comment-box"
                        placeholder="Share reference or any comment"></textarea>
                </div>
                <div class="form-field font-field">
                    <ngx-file-drop (onFileDrop)="dropped($event,'font')" dropZoneClassName="dropZoneClass"
                        (onFileOver)="fileOver($event,'font')" (onFileLeave)="fileLeave($event)">
                        <ng-template ngx-file-drop-content-tmp *ngIf="showFileOverlayFont">
                            <span>
                                <img src="../../../assets/images/upload-white.png">
                            </span>
                        </ng-template>
                    </ngx-file-drop>
                    <h3 class="field-title">Add your font family</h3>
                    <p class="field-desc">
                        Select the font family you want us to use for your app/website. We’ve a free font list
                        below, if you can’t find yours, upload your font file.
                    </p>

                    <!--Uploading loader start here-->
                    <div class="fileUploading" *ngIf="fontLoader">
                        <p>{{currentFileNameFont}}</p>
                        <div class="loaderFileName">
                            <div class="fileUploadingLoader"></div>
                        </div>
                    </div>
                    <!--Uploading loader end here-->

                    <div class="custom-dropdown" *ngIf="!fontLoader">
                        <div class="selected-font">
                            <span class="placeholder" *ngIf="fontSelected.length == 0">Select Font</span>
                            <ul class="font-list" *ngIf="fontSelected.length > 0">
                                <li class="font-token" *ngFor="let selItem of fontSelected">
                                    <span [ngStyle]="{'font-family': selItem.name}">{{selItem.name}}</span>
                                    <span class="icon-iconcross" (click)="removeSelected(selItem)"></span>
                                </li>
                                <span class="icon-iconcross" (click)="removeAllSelected()"></span>
                            </ul>
                            <span class="caret" [ngClass]="dropDownShow ? 'up' : 'down'" (click)="dropBoxHandler()"></span>
                        </div>
                        <div class="font-dropdown-wrap" *ngIf="dropDownShow">
                            <span class="font-search">
                                <input class="inputCheck" type="text" (keyup)="searchFilter()"
                                       placeholder="Search"
                                       [(ngModel)]="searchQuery"
                                       name="searchQuery"> <span class="icon-iconcross" *ngIf="showClearAll" (click)="clearAll()"></span>
                            </span>
                            <ul class="font-all-list">
                                <perfect-scrollbar>
                                    <li class="font-element" *ngFor="let font of fontFiterList">
                                        <label [ngClass]="{'selected': fontSelected.includes(font)}" [ngStyle]="{'font-family': font.name}"><input type="checkbox" class="itemCheck" (change)="onItemSelect($event, font)">{{font.name}}</label>
                                    </li>
                                </perfect-scrollbar>
                            </ul>
                        </div>
                    </div>
                    <div class="uploaded-logo-wrap" *ngIf="!fontLoader">
                        <div class="uploaded-logo" *ngFor="let fileArray of formModel.font_attachments?.files">
                            <span class="name">{{fileArray.name}}</span>
                            <span class="remove icon-crossnew" (click)="remove(fileArray,'font')"></span>
                        </div>

                    </div>
                    <p *ngIf="!fontFileArea && !fontLoader" class="comment-txt mt">Or you can <span (click)="changeState('fontFileArea')"
                         class="highlight-color">Upload your
                            font</span></p>
                    <ng-container *ngIf="fontFileArea && !fontLoader">
                        <button class="form-btn field-dotted-btn">Upload Font<input class="form-inupt-overlay"
                                (change)="uploadFile($event,'font')" type="file" accept=".ttf,.ttc,.otf,.fon,.afm,.tte,.ffil,.euf"></button>
                    </ng-container>

                </div>
                <div class="form-field color-field">
                    <h3 class="field-title">Add your brand colour</h3>
                    <p class="field-desc"> This will be your main brand colour, you can also add your secondary
                        colour and any other colours. And if you’ve reference of how to use them, add that too.</p>

                    <!--Uploading loader start here-->
                    <!-- <div class="fileUploading" *ngIf="colourLoader">
                        <p>Attachment Name here</p>
                        <div class="loaderFileName">
                            <div class="fileUploadingLoader"></div>
                        </div>
                    </div> -->
                    <!--Uploading loader end here-->

                    <div class="form-color-picker">
                        <span class="custom_Css"
                            *ngFor="let item of formModel.colour_attachments?.custom_attribute; let i=index"
                            [style.background]="item">
                            <span [ngSwitch]="i">
                                <span *ngSwitchCase="0" class="color-identity">Primary colour</span>
                                <span *ngSwitchCase="1" class="color-identity">Secondary colour</span>
                                <span *ngSwitchCase="2" class="color-identity">Tertiary colour</span>
                                <span *ngSwitchDefault class="color-identity">Default colour</span>
                            </span>
                            <span class="remove-link" (click)="removeColor(item)">Remove</span>
                        </span>
                        <ng-container *ngIf="showPicker">
                            <span class="custom_Css add_btn" [cpOKButton]="true" [cpCancelButton]="true"
                                [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                [(colorPicker)]="colorPickerModel" (colorPickerSelect)="addColor(colorPickerModel)">
                            </span>
                        </ng-container>
                    </div>
                    <p *ngIf="!colorTextArea" class="comment-txt">Or you can <span (click)="changeState('colorTextArea')"
                        class="highlight-color">Share any
                            reference or any comment</span></p>
                    <textarea *ngIf="colorTextArea" [(ngModel)]="formModel.colour_attachments.reference"
                        (ngModelChange)="enableButton()" class="comment-box"
                        placeholder="Share reference or any comment"></textarea>
                </div>
                <div class="form-field icon-field">
                    <h3 class="field-title">Select icon style</h3>
                    <p class="field-desc">Which style of icons do you want your designer to use?</p>

                    <!--Uploading loader start here-->
                    <!-- <div class="fileUploading" *ngIf="iconLoader">
                        <p>Attachment Name here</p>
                        <div class="loaderFileName">
                            <div class="fileUploadingLoader"></div>
                        </div>
                    </div> -->
                    <!--Uploading loader end here-->

                    <ul class="icon-wrap">
                        <li class="icon-entity selected" *ngFor="let icons of baseIcons" (click)="icon_Selection(icons)"
                            [ngClass]="{'selected':icons.selected}">
                            <img class="entity-img" [src]="icons.source">
                        </li>
                        <!-- <li class="icon-entity">
                                <img class="entity-img" src="../../../assets/images/cart-fill.png">
                            </li> -->
                    </ul>
                    <p *ngIf="!iconTextArea" class="comment-txt">Or you can <span (click)="changeState('iconTextArea')"
                         class="highlight-color">Share any
                            reference or any comment</span></p>
                    <textarea *ngIf="iconTextArea" [(ngModel)]="formModel.icon_attachments.reference"
                        (ngModelChange)="enableButton()" class="comment-box"
                        placeholder="Share reference or any comment"></textarea>
                </div>
                <div class="form-field illustration-field">
                    <h3 class="field-title">Add Illustration style <span class="info icon-info-circle"></span></h3>
                    <p class="field-desc">If you’ve a specific illustration style or preferences about the vector
                        graphics we use in your app/website, share it here.</p>

                    <!--Uploading loader start here-->
                    <!-- <div class="fileUploading" *ngIf="illustrationLoader">
                        <p>Attachment Name here</p>
                        <div class="loaderFileName">
                            <div class="fileUploadingLoader"></div>
                        </div>
                    </div> -->
                    <!--Uploading loader end here-->

                    <textarea [(ngModel)]="formModel.illustration_attachments.reference"
                        (ngModelChange)="enableButton()" class="comment-box"
                        placeholder="Share reference or any comment"></textarea>
                </div>
                <div class="form-field other-field">
                    <ngx-file-drop (onFileDrop)="dropped($event,'other')" dropZoneClassName="dropZoneClass"
                        (onFileOver)="fileOver($event,'other')" (onFileLeave)="fileLeave($event)">
                        <ng-template ngx-file-drop-content-tmp *ngIf="showFileOverlayOther">
                            <span>
                                <img src="../../../assets/images/upload-white.png">
                            </span>
                        </ng-template>
                    </ngx-file-drop>
                    <h3 class="field-title">Other requirements</h3>
                    <p class="field-desc">Anything else that would help our designer get your designs on brand,
                        first time?</p>

                    <!--Uploading loader start here-->
                    <div class="fileUploading" *ngIf="otherLoader">
                        <p>{{currentFileNameOther}}</p>
                        <div class="loaderFileName">
                            <div class="fileUploadingLoader"></div>
                        </div>
                    </div>
                    <!--Uploading loader end here-->
                   <ng-container *ngIf="!otherLoader">
                    <h5 class="field-sub-title">You can share any other file.</h5>
                    <button class="form-btn field-dotted-btn">Upload Files <input class="form-inupt-overlay"
                            (change)="uploadFile($event,'other')" type="file">
                    </button>
                    <div class="uploaded-logo-wrap">
                        <div class="uploaded-logo" *ngFor="let fileArray of formModel.other_attachments?.files; let fileIndex = index;">
                            <!--<span class="name">{{fileArray.name}}</span>-->
                            <!--<span class="remove icon-crossnew" (click)="remove(fileArray,'other')"></span>-->

                            <div class="filesPlatform">
                                <span class="delete" (click)="remove(fileArray,'other')"><em class="icon-plus"></em></span>
                                <div class="filesBlock">
                                    <label *ngIf="utilityService.isSketch(fileArray.name)">Sketch file</label>
                                    <div class="files">
                                        <img *ngIf="!utilityService.isSketch(fileArray.name) && !utilityService.isPdf(fileArray.name) && !utilityService.isSvg(fileArray.name)" src="../../../assets/images/image_icon.png" alt="">
                                        <img *ngIf="utilityService.isPdf(fileArray.name)" src="../../../assets/images/pdf_icon.svg" alt="">
                                        <img *ngIf="utilityService.isSvg(fileArray.name)" src="../../../assets/images/svg_icon.svg" alt="">
                                        <img *ngIf="utilityService.isSketch(fileArray.name)" src="../../../assets/images/sketchUploadIcon.png" alt="">
                                        <span class="name">{{fileArray.name}}</span>
                                    </div>
                                </div>
                                <div class="platformBlock">
                                    <label *ngIf="utilityService.isSketch(fileArray.name)">Select platform</label>
                                    <div class="platform" *ngIf="utilityService.isSketch(fileArray.name)">
                                        <div class="mob-web-btn-wrapper"  [ngClass]="{
                                            'one': dataCommService.headerloop?.length === 1,
                                            'two': dataCommService.headerloop?.length === 2,
                                            'three': dataCommService.headerloop?.length === 3,
                                            'four': dataCommService.headerloop?.length === 4}"> <!--please add class one,two,three,four here-->
                                            
                                            <div *ngFor="let platforms of dataCommService.headerloop; let i =index;"
                                                [ngClass]="{
                                                    'mob-btn':platforms?.title == 'mobile',
                                                    'web-btn':platforms?.title == 'web',
                                                    'tab-btn':platforms?.title == 'tablet',
                                                    'watch-btn':platforms?.title == 'watch',
                                                    'disabled':platforms?.comingSoon,
                                                    'active':(selectedFileIndex == fileIndex && clickedPlatformIndex === i && arrowUpFlag),
                                                    'arrowUp': (selectedFileIndex == fileIndex && clickedPlatformIndex === i && arrowUpFlag)
                                                }"
                                                class="mob-web-btn"
                                                (click)="showPlatforms(platforms, i, fileIndex)">
                                                <span class="icon-web"
                                                [ngClass]="{
                                                    'icon-mobile':platforms?.title == 'mobile',
                                                    'icon-desktop-1':platforms?.title == 'web',
                                                    'icon-icon-tab':platforms?.title == 'tablet',
                                                    'icon-icon-watch':platforms?.title == 'watch'
                                                }"></span>
                                                <em class="icon-caret-down"></em>
                                            </div>
        
                                            <!--Platform tooltip start here-->
        
                                            <div class="platformsBlock" *ngIf="(selectedFileIndex == fileIndex && openDropDownBoolean)">
                                                
                                                <h3>Select a {{platformName == 'web' ? 'desktop' : platformName }} platform</h3>
                                                <ul>
                                                    <li *ngFor="let item of selectedValues.values; let j=index;" class="platFormCheckList"> <!--class="active"-->
                                                        <input class="styled-checkbox" id="checkbox{{j}}" type="checkbox" (change)="onPlatformSelect($event, item, fileIndex)" [checked]="item.selected" [disabled]="item.is_active">
                                                        <label for="checkbox{{j}}">
                                                            <p>{{item?.title}}</p>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
        
                                            <!--Platform tooltip end here-->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-container>
                    <h5 *ngIf="!otherLoader" class="field-sub-title">Share any other details or ideas with us.</h5>
                    <textarea *ngIf="!otherLoader" [(ngModel)]="formModel.other_attachments.reference" (ngModelChange)="enableButton()"
                        class="comment-box" placeholder="Share reference or any comment"></textarea>
                </div>
                <div class="grayedOutPlaced" *ngIf="greyBg"></div>
            </div>
        </perfect-scrollbar>
        <button *ngIf="saveButton" class="form-btn form-solid-btn" (click)="saveForm()" [disabled]="disableButton">Save <span class="loading" *ngIf="showSpinner"><img src="../../../../../../assets/images/spinner.gif"> </span></button>
        <button *ngIf="fileUploadingButton" class="form-btn form-solid-btn stillUploading" [disabled]="disableButton">{{ctaText}}</button>
    </div>
</div>

<!-- </form> -->
