import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthService as Auth} from '@core/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {DataService} from '../../../../../main/shared/services/data.service';
import {UserModel} from '@shared/models/user.model';
import {Router} from '@angular/router';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { Constants } from '@shared/services/constants';
import { CookieService } from '@core/cookie.service';
import {CommentService} from "@shared/components/comments/comment.service";
import { environment } from '@env/environment';
import { ToasterService } from '@core/toaster.service';
@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent implements OnInit {

  loginSignupForm: FormGroup;
  invalidEmail = false;
  invalidPassword = false;
  invalidName = false;
  invalidPhone = false;
  signupTerms = false;
  @ViewChild('mobileNumber', { static: false }) public mobileNumber;
  isRegisterClicked = false;
  loginClicked = false;
  showLoginScreen;
  screenTobeAppear = '';
  appName: string = '';
  currencies = [
                {
                  "id": 3,
                  "name": "Euro",
                  "code": "EUR",
                  "exchange_rate": 0.894696,
                  "multiplier": 1,
                  "symbol": "€",
                  "country_code": "EU",
                  "specing_price": 220,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/59fd4ef688f3ac67bbadfda8/euro-symbol__1_.png",
                  "instant_spec_price": 300,
                  "tax": 0,
                  "custom_prototype_price": 1500,
                  "post_upfront_price": 0
                },
                {
                  "id": 7,
                  "name": "Ringgit",
                  "code": "MYR",
                  "exchange_rate": 4.272501,
                  "multiplier": 1,
                  "symbol": "RM",
                  "country_code": "MYS",
                  "specing_price": 1000,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/591a9aa614c49f7f467463f5/Ringgit.svg",
                  "instant_spec_price": 700,
                  "tax": 0,
                  "custom_prototype_price": 1000,
                  "post_upfront_price": 0
                },
                {
                  "id": 4,
                  "name": "Pounds",
                  "code": "GBP",
                  "exchange_rate": 0.795144,
                  "multiplier": 1,
                  "symbol": "£",
                  "country_code": "UK",
                  "specing_price": 190,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/59fd4e9588f3ac67bbadfda0/pounds-symbol__1_.png",
                  "instant_spec_price": 400,
                  "tax": 0,
                  "custom_prototype_price": 2000,
                  "post_upfront_price": 0
                },
                {
                  "id": 5,
                  "name": "YEN",
                  "code": "JPY",
                  "exchange_rate": 104.78945833,
                  "multiplier": 1,
                  "symbol": "¥",
                  "country_code": "JP",
                  "specing_price": 28374,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/5bdfefe51f0c640e7cfadee0/yen.png",
                  "instant_spec_price": 500,
                  "tax": 0,
                  "custom_prototype_price": 5000,
                  "post_upfront_price": 0
                },
                {
                  "id": 6,
                  "name": "Dirham",
                  "code": "AED",
                  "exchange_rate": 3.67295,
                  "multiplier": 1,
                  "symbol": "د.إ",
                  "country_code": "UAE",
                  "specing_price": 918,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/5ab3ab3c0b82ec47eda97d71/AED_0.5x.png",
                  "instant_spec_price": 600,
                  "tax": 0,
                  "custom_prototype_price": 2500,
                  "post_upfront_price": 0
                },
                {
                  "id": 9,
                  "name": "Saudi Riyal",
                  "code": "SAR",
                  "exchange_rate": 3.754035,
                  "multiplier": 1,
                  "symbol": "ر.س",
                  "country_code": "SA",
                  "specing_price": 0,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/447/SAR.png",
                  "instant_spec_price": 0,
                  "tax": 0,
                  "custom_prototype_price": 3000,
                  "post_upfront_price": 0
                },
                {
                  "id": 2,
                  "name": "US Dollars",
                  "code": "USD",
                  "exchange_rate": 1,
                  "multiplier": 1,
                  "symbol": "$",
                  "country_code": "US",
                  "specing_price": 250,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/591a9aa814c49f7f467463f7/Dollar.svg",
                  "instant_spec_price": 200,
                  "tax": 0,
                  "custom_prototype_price": 1000,
                  "post_upfront_price": 500
                },
                {
                  "id": 8,
                  "name": "Canadian Dollar",
                  "code": "CAD",
                  "exchange_rate": 1.392326,
                  "multiplier": 1,
                  "symbol": "C$",
                  "country_code": "CAN",
                  "specing_price": 0,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/5ce3f28e97b81512aee0fabc/Canada.png",
                  "instant_spec_price": 800,
                  "tax": 0,
                  "custom_prototype_price": 1000,
                  "post_upfront_price": 500
                },
                {
                  "id": 1,
                  "name": "Indian Rupee",
                  "code": "INR",
                  "exchange_rate": 74.4166,
                  "multiplier": 0.3,
                  "symbol": "₹",
                  "country_code": "IN",
                  "specing_price": 4999,
                  "icon_image_file_url": "https://stg-studio-asset.builder.ai/stg-assets/uploads/image/file/591a9aa714c49f7f467463f6/Rupee.svg",
                  "instant_spec_price": 2499,
                  "tax": 18,
                  "custom_prototype_price": 64000,
                  "post_upfront_price": 100
                },
                {
                  "id": 10,
                  "name": "Singapore Dollar",
                  "code": "SGD",
                  "exchange_rate": 1.37,
                  "multiplier": 1,
                  "symbol": "S$",
                  "country_code": "SG",
                  "specing_price": 0,
                  "icon_image_file_url": "https://bstudio-staging.azureedge.net/stg-assets/uploads/image/file/450/Singapore_Dollar.svg",
                  "instant_spec_price": 0,
                  "tax": 0,
                  "custom_prototype_price": 5000,
                  "post_upfront_price": 0,
                }
              ];
  showCurrencyDropdown = false;
  selectedCurrency: any;
  btnCtaName = 'Sign Up';
  invalidEmailErrMeggase: string;
  userData: any;

  constructor(private fb: FormBuilder, public dataCommService: DataCommService, private dataService: DataService,
              public authService: Auth, private router: Router, private cookieService: CookieService,
              public socialAuth: SocialAuthService, public commentService: CommentService, public toasterService: ToasterService) {
    this.authService.setDomain();
    if (!this.authService.isLoggedInUser()) {
        this.screenTobeAppear = 'email';
    } else if (!this.authService.isCurrencyPresentInUser()) {
      this.screenTobeAppear = 'currency';
    } else {
      this.screenTobeAppear = 'email';
    }
  }

  ngOnInit(): void {
    this.authService.countryNameCode = 'in';
    // this.screenTobeAppear = this.showLoginScreen ? 'login' : 'signup';
    this.showLoginScreen = this.dataCommService.touchPoint === Constants.touchPoints.putComment;
    if (this.showLoginScreen && this.screenTobeAppear === 'login') {
      this.btnCtaName = 'Login';
    }
    this.dataCommService.screenTobeAppear = this.screenTobeAppear;
    let payload = {
      referrer_trigger: this.dataCommService.referrer,
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    };
    this.dataCommService.getTouchPointHeadings('checkEmail');
    this.initializeLoginForm();
  }

  @HostListener('document:mousedown', ['$event.target'])
  public onMouseDownFun(targetElement) {
    if(targetElement.classList.contains('selectedCurrency')) {
        this.showCurrencyDropdown = !this.showCurrencyDropdown;
    } else if (targetElement.classList.length > 0 && !(targetElement.classList.contains('currencyicon'))
        && !(targetElement.classList.contains('currencyname'))) {
        this.showCurrencyDropdown = false;
    }
  }


  initializeLoginForm() {
    this.loginSignupForm = this.fb.group({
      email: [''],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', Validators.required, Validators.minLength]
    });
  }

  get email() {
    return this.loginSignupForm.get('email');
  }

  get password() {
    return this.loginSignupForm.get('password');
  }

  get phone() {
    return this.loginSignupForm.get('phone');
  }

  get name() {
    return this.loginSignupForm.get('name');
  }

  checkIfEmailValid(): boolean {
    return !this.dataCommService.validateEmail(this.email.value);
  }

  checkIfPasswordValid(isSignIn?: boolean): boolean {
    if (isSignIn) {
      return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
    } else if (!isSignIn) {
      if (this.isRegisterClicked) {
        return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
      } else {
        return this.password && this.password.errors && (this.password.dirty || this.password.touched);
      }
    }
  }

  checkIfNameValid(): boolean {
    if (this.isRegisterClicked) {
      return this.name && this.name.errors && (this.name.invalid || this.name.pristine);
    } else {
      return this.name && this.name.errors && (this.name.dirty || this.name.touched);
    }
  }

  public checkIfPhoneValid(): boolean {
    if (this.isRegisterClicked) {
      return this.phone && !!this.phone.errors && (this.phone.invalid || this.phone.pristine);
    } else {
      return this.phone && !!this.phone.errors && (this.phone.dirty || this.phone.touched);
    }
  }

  showLoginOrSignup(screenName) {
    this.screenTobeAppear = screenName;
    this.dataCommService.screenTobeAppear = screenName;
    this.dataCommService.newSignUpFlowScreen = screenName;
    if (this.screenTobeAppear === 'signup') {
      this.btnCtaName = 'Sign Up';
      this.dataCommService.getTouchPointHeadings('signup');
    } else if (this.screenTobeAppear === 'login') {
      this.btnCtaName = 'Sign In';
      this.dataCommService.getTouchPointHeadings('signin');
    }
    if (screenName === 'login') {
    } else if (screenName === 'signup') {
      const queryParams = this.dataCommService.setQueryparams();
      if (queryParams && queryParams['?red'] === 'now') {
        const segmentTrackerData = {
          referrer_trigger: this.dataCommService.referrer,
          user_id: this.dataCommService.userUniqueid,
          user_browser: this.dataCommService.userBrowser,
          user_device: this.dataCommService.userDevice
        };
        this.dataCommService.trackEvent('sign_up_screen_opened', segmentTrackerData);
      } else if (this.dataCommService.isUserClickedOnPreviewToPutComment) {
        const segmentTrackerData = {
          referrer_trigger: this.dataCommService.referrer,
          user_id: this.dataCommService.userUniqueid,
          user_browser: this.dataCommService.userBrowser,
          user_device: this.dataCommService.userDevice
        };
        this.dataCommService.trackEvent('sign_up_screen_opened', segmentTrackerData);
      } else {
        const segmentTrackerData = {
          referrer_trigger: this.dataCommService.referrer,
          user_id: this.dataCommService.userUniqueid,
          user_browser: this.dataCommService.userBrowser,
          user_device: this.dataCommService.userDevice
        };
        this.dataCommService.trackEvent('sign_up_screen_opened', segmentTrackerData);
      }
      if (queryParams && queryParams['?red'] === 'now') {
        const segmentTrackerData = {
          screen_name: 'Builder Now Campaign',
        };
        this.dataCommService.trackEvent('SignUp clicked', segmentTrackerData);
      } else if (this.dataCommService.isUserClickedOnPreviewToPutComment) {
        const segmentTrackerData = {
          screen_name: 'Builder Now Comments',
        };
        this.dataCommService.trackEvent('SignUp clicked', segmentTrackerData);
      } else {
        const payload = {
          user_id: this.dataCommService.userUniqueid,
          user_browser: this.dataCommService.userBrowser,
          user_device: this.dataCommService.userDevice,
          clicked_source: 'Normal'
        };
        this.dataCommService.trackEvent('sign_up_clicked', payload);
      }
    }
  }

  onFlagChange() {
    if (this.mobileNumber && this.phone.valid && this.mobileNumber.selectedCountry) {
      this.mobileNumber.countryNameCode = this.mobileNumber.selectedCountry.dialCode;
      this.dataService.countryCode = this.mobileNumber.selectedCountry.dialCode;
    }
  }

  loginSignup() {
    this.loginClicked = true;
    let userData;
    this.validateEmail();
    if (this.screenTobeAppear === 'login') {
      this.validatePassword(true);
      if (this.invalidEmail || this.invalidPassword) {
        return;
      }
      delete this.loginSignupForm.value.name;
      delete this.loginSignupForm.value.phone;
      this.loginSignupForm.value.password = this.password.value;
      this.dataCommService.trackEvent('sign_in_clicked', {
        user_id: this.email.value,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice,
        screen_source: 'Builder Now'
      });
    } else {
      this.isRegisterClicked = true;
      this.validateName();
      this.validatePhone();
      this.validatePassword(false);

      if (this.invalidEmail || this.invalidPassword || this.invalidName || this.invalidPhone) {
        return;
      }

      // if (!this.signupTerms || !this.selectedCurrency) {
      //   this.loginClicked = true;
      //   return;
      // }
      userData = {
        first_name: this.name.value,
        last_name: '',
        email: this.email.value,
        password: this.password.value,
        // phone_number: this.phone.value,
        phone_number: this.phone.value.e164Number,
        user_type: 'User',
        currency_id: this.selectedCurrency.id
      };
      if (this.name.value.includes(' ')) {
        userData.first_name = this.name.value.split(' ')[0];
        userData.last_name = this.name.value.split(' ')[1];
      }
    }
    const data = (this.screenTobeAppear === 'login') ? this.loginSignupForm.value : userData;
    this.dataCommService.proceedClickedFromRental = true;
    this.dataService.login_signup(data, this.screenTobeAppear).subscribe((resp: any) => {
      if (this.btnCtaName === 'Sign Up') {
        this.dataCommService.signUpClickedFlag = true;
        this.dataCommService.trackCustomerSignUp.next(resp.user);
      }
      const userObj: UserModel = new UserModel();
      userData = this.dataCommService.getMappedModel(userObj, resp.user);
      this.authService.setCookie(userData);
      this.authService.setUserProfile();
      this.dataCommService.assignGuestBuildCardToUser();
      //this.dataCommService.releaseAndMaintenance(this.email.value);
      if (this.cookieService.getLocalStorage(environment.ONBOARDING_COOKIE)) {
        this.dataCommService.onBoarding = JSON.parse(this.cookieService.getLocalStorage(environment.ONBOARDING_COOKIE));
      } else {
        this.dataCommService.onBoarding.howItWorks = true;
        this.dataCommService.onBoarding.inProgressEditMode = true;
        this.dataCommService.onBoarding.inProgressPrototype = true;
      }
      // if (this.dataCommService.onBoarding.inProgressEditMode) {
      //   this.dataCommService.onBoarding.howItWorks = true;
      // }
      this.dataCommService.isCpe = this.authService.isMasterTemplateUser();
      this.dataCommService.showLoaderForPrototpeCreation = true;
      this.dataCommService.showHidePaymentFlow(false);
      if (this.showLoginScreen) {
        if (this.screenTobeAppear === 'login') {
          this.dataCommService.trackEvent('sign_in_successful', {
            user_id: this.email.value,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            screen_source: 'Builder Now',
            platform_source: 'email'
          });
          this.cookieService.setLocalStorage('userType', 'Returning');
        } else {
          const payload = {
            user_id: this.email.value,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            screen_source: 'Builder Now',
            platform_source: 'email',
            signup_type: this.cookieService.getLocalStorage('guestWarningPopup') === 'yes' ? 'Banner' : 'normal'
          };
          this.dataCommService.trackEvent('sign_up_successful', payload);
          this.cookieService.setLocalStorage('userType', 'New');
        }
      } else {
        if (this.screenTobeAppear === 'login') {
          this.dataCommService.trackEvent('sign_in_successful', {
            user_id: this.email.value,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            screen_source: 'Builder Now',
            platform_source: 'email'
          });
          this.cookieService.setLocalStorage('userType', 'Returning');
        } else {
          const payload = {
            user_id: this.email.value,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            screen_source: 'Builder Now',
            platform_source: 'email',
            signup_type: this.cookieService.getLocalStorage('guestWarningPopup') === 'yes' ? 'Banner' : 'normal'
          };
          this.dataCommService.trackEvent('sign_up_successful', payload);
          this.cookieService.setLocalStorage('userType', 'New');
        }
      }
/*      if (this.dataCommService.touchPoint === Constants.touchPoints.putComment) {
        this.dataCommService.loginSuccessFun(true);
      } else {
        this.commentService.commentModeToggleSubject.next(false);
      }*/
      if (!this.dataCommService.isGuestUser && (this.dataCommService.touchPoint === Constants.touchPoints.previewHeader || this.dataCommService.touchPoint === Constants.touchPoints.putComment)) {
        this.cookieService.setCookie('buildernow_visited', 'true');
        this.dataCommService.featureList = [];
          this.commentService.commentModeToggleSubject.next(false);
        if (this.authService.cutsotmPrototypeVersion) {
          const dcs = this.dataCommService;
          this.router.navigateByUrl(`${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/preview/${dcs.platform}/${dcs.platformType}`);
        } else {
          this.router.navigateByUrl(`${this.dataCommService.buildcardId}/preview/${this.dataCommService.platform}/${this.dataCommService.platformType}`);
        }
      }
      if (this.dataCommService.touchPoint === Constants.touchPoints.campaignLink) {
        if (this.dataCommService.urlSearchparams) {
          this.appName = this.dataCommService.urlSearchparams['application'];
          this.dataService.createAndSyncBuildCardToNow(this.dataCommService.urlSearchparams['application']).subscribe((res: any) => {
            this.dataCommService.urlSearchparams = {};
            this.cookieService.setCookie('buildernow_visited', 'true');
            this.dataCommService.buildCardData = res.data.attributes;
            this.dataCommService.showHidePaymentFlow(false);
            this.dataCommService.showLoaderForPrototpeCreation = true;
            if (this.appName === 'create-from-scratch') {
              this.getInitialPlatform();
            } else {
              this.router.navigate(['/' + this.dataCommService.buildCardData.id + '/preview']);
            }
          }, (err) => {
            this.dataCommService.showLoaderForPrototpeCreation = false;
          });
        }
      }
    }, error => {
      if (Object.keys(error.errors).includes('email')) {
        this.invalidEmail = true;
        this.invalidPassword = false;
        this.invalidEmailErrMeggase = error.message.includes('Sign up') ?
            'Your email is already registered with us.' : 'Please register your email.';
      }
      if (Object.keys(error.errors).includes('password')) {
        this.invalidEmail = false;
        this.invalidPassword = true;
      }
      this.dataCommService.proceedClickedFromRental = false;
      this.dataCommService.showLoaderForPrototpeCreation = false;
    });
  }

  selecyCurrecy(currency, event) {
    event.stopPropagation();
    this.selectedCurrency = currency;
    this.showCurrencyDropdown = false;
  }

  clearMessage() {
    this.invalidEmailErrMeggase = '';
  }

  validateEmail() {
    if (this.checkIfEmailValid()) {
      this.invalidEmail = true;
      return;
    } else {
      this.invalidEmail = false;
    }
  }
  validatePassword(flag) {
    if (this.checkIfPasswordValid(flag)) {
      this.invalidPassword = true;
      return;
    } else {
      this.invalidPassword = false;
    }
  }

  validateName() {
    if (this.checkIfNameValid()) {
      this.invalidName = true;
      return;
    } else {
      this.invalidName = false;
    }
  }

  validatePhone() {
    if (this.checkIfPhoneValid()) {
      this.invalidPhone = true;
      return;
    } else {
      this.invalidPhone = false;
    }
  }

  validateField(fieldName) {
    switch(fieldName) {
      case 'email':
        this.clearMessage();
        this.validateEmail();
        break;
      case 'password':
        this.validatePassword(false);
        break;
      case 'name':
        this.validateName();
        break;
      case 'phone':
        this.onFlagChange();
        this.validatePhone();
        break;
    }
    return;
  }

  sendTrackEvent(type, event: any = '') {
    if (event === '' || event.target.checked === true) {
      this.dataCommService.trackEvent(type, {screen_name : this.dataCommService.findScreenForEvent()});
    }
  }
  checkEmailBeforeSigninSignup() {
    this.validateEmail();
    const queryParams = this.dataCommService.setQueryparams();
    this.dataService.checkEmailBeforeSigninSignup(this.email.value).subscribe((res: Response) => {
      this.screenTobeAppear = 'login';
      this.btnCtaName = 'Login';
      this.dataCommService.getTouchPointHeadings('signin');
    }, error => {
      this.screenTobeAppear = 'signup';
      this.btnCtaName = 'Sign Up';
      this.dataCommService.getTouchPointHeadings('signup');
    });
  }

  public social_sign(provider) {
    this.socialAuth.signOut();
    if (provider === 'google') {
      this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID);
    } else if (provider === 'facebook') {
      this.socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID);
    }
    this.socialSignAuthState(provider);
  }
  public socialSignAuthState(provider) {
    this.socialAuth.authState.subscribe((socialUser) => {
       const user = socialUser;
       if (user) {
         this.dataService.login_signup(user, 'login').subscribe((resp: any) => {
           if (resp.user.currency.data === null) {
             this.screenTobeAppear = 'currency';
             this.dataCommService.isCommentSigninSignUpForm = false;
             const userObj: UserModel = new UserModel();
             let userData;
             userData = this.dataCommService.getMappedModel(userObj, resp.user);
             this.authService.setCookie(userData);
             this.dataCommService.trackEvent('sign_up_successful', {
               user_id: this.dataCommService.userUniqueid,
              user_browser: this.dataCommService.userBrowser,
              user_device: this.dataCommService.userDevice,
              screen_source: 'Builder Now',
              platform_source: provider,
              signup_type: this.cookieService.getLocalStorage('guestWarningPopup') === 'yes' ? 'Banner' : 'normal'
             });
             this.cookieService.setLocalStorage('userType', 'New');
             this.authService.setUserProfile();
             this.dataCommService.assignGuestBuildCardToUser();
             //this.dataCommService.releaseAndMaintenance(user.email);
             return false;
           } else {
             const providerForevent = (provider === 'google' ? 'Google SignIn' : 'Facebook SignIn');
             const userObj: UserModel = new UserModel();
             let userData;
             userData = this.dataCommService.getMappedModel(userObj, resp.user);
             this.authService.setCookie(userData);
             this.authService.setUserProfile();
             this.dataCommService.assignGuestBuildCardToUser();
             //this.dataCommService.releaseAndMaintenance(userData.email);
             this.dataCommService.trackEvent('sign_in_successful', {
              user_id: this.dataCommService.userUniqueid,
              user_browser: this.dataCommService.userBrowser,
              user_device: this.dataCommService.userDevice,
              screen_source: 'Builder Now',
              platform_source: providerForevent
             });
             this.cookieService.setLocalStorage('userType', 'Returning');
             if (this.dataCommService.touchPoint === Constants.touchPoints.putComment) {
               this.dataCommService.loginSuccessFun(true);
             } else {
               this.commentService.commentModeToggleSubject.next(false);
             }
             this.dataCommService.showLoaderForPrototpeCreation = true;
             this.dataCommService.showHidePaymentFlow(false);
             if (!this.dataCommService.isGuestUser && this.dataCommService.touchPoint === Constants.touchPoints.previewHeader) {
               this.dataCommService.featureList = [];
               if (this.authService.cutsotmPrototypeVersion) {
                 this.router.navigateByUrl(this.dataCommService.buildcardId + '/' + this.authService.cutsotmPrototypeVersion + '/preview');
               } else {
                 this.router.navigateByUrl(this.dataCommService.buildcardId + '/preview');
               }
             }
             if (this.dataCommService.touchPoint === Constants.touchPoints.campaignLink) {
              if (this.dataCommService.urlSearchparams['application']) {
                this.appName = this.dataCommService.urlSearchparams['application'];
                this.dataService.createAndSyncBuildCardToNow(this.dataCommService.urlSearchparams['application']).subscribe((res: any) => {
                  this.dataCommService.urlSearchparams = {};
                  this.cookieService.setCookie('buildernow_visited', 'true');
                  this.dataCommService.buildCardData = res.data.attributes;
                  this.dataCommService.showHidePaymentFlow(false);
                  this.dataCommService.showLoaderForPrototpeCreation = true;
                  if (this.appName === 'create-from-scratch') {
                    this.getInitialPlatform();
                  } else {
                    window.location.href = '/' + this.dataCommService.buildCardData.id + '/preview';
                  }
                });
              }
            }
           }
         });
       }
      }, (error) => {
        console.log(error);
    });
  }
  updateCurrency() {
    const currencyObj = new FormData();
    currencyObj.append('user[currency_id]', this.selectedCurrency.id);
    this.dataService.updateCurrency(currencyObj).subscribe((res: any) => {
      const userObj: UserModel = new UserModel();
      let userData;
      userData = this.dataCommService.getMappedModel(userObj, res.user);
      this.authService.setCookie(userData);
      this.authService.setUserProfile();
      if (this.dataCommService.touchPoint === Constants.touchPoints.putComment) {
        this.dataCommService.loginSuccessFun(true);
      }
      this.dataCommService.showLoaderForPrototpeCreation = true;
      // this.dataCommService.showLoginPopup = false;
      this.dataCommService.showHidePaymentFlow(false);
      if (this.dataCommService.touchPoint === Constants.touchPoints.campaignLink) {
        this.dataService.createAndSyncBuildCardToNow(this.dataCommService.urlSearchparams['application']).subscribe((result: any) => {
          this.dataCommService.urlSearchparams = {};
          this.cookieService.setCookie('buildernow_visited', 'true');
          this.dataCommService.buildCardData = result.data.attributes;
          this.dataCommService.showHidePaymentFlow(false);
          this.dataCommService.showLoaderForPrototpeCreation = true;
          this.router.navigate(['/' + this.dataCommService.buildCardData.id + '/preview']);
        });
      }
    });
  }
  forgotPasswordPopup() {
    this.dataCommService.selectedSectionIndex = 7;
    this.dataCommService.getTouchPointHeadings('forgotPassword');
  }
  goToPreviousScreen() {
    this.screenTobeAppear = 'email';
    this.dataCommService.getTouchPointHeadings('checkEmail');
  }
  onCountryChange(event) {
    this.loginSignupForm.patchValue({ phone: '' });
  }

  getInitialPlatform() {
    this.dataService.getPlatforms(this.dataCommService.buildCardData.id).subscribe((response: any) => {
      if (!response.platforms || (response.platforms && response.platforms.length === 0)) {
          setTimeout(() => {
              this.getInitialPlatform();
          }, 3000);
      }
      if (response.platforms[0].device && response.platforms[0].title) {
        this.dataCommService.platform = response.platforms[0].device;
        this.dataCommService.platformType = response.platforms[0].title.toLowerCase();
        this.router.navigate(['/' + this.dataCommService.buildCardData.id + '/dashboard/' + this.dataCommService.platform + '/' + this.dataCommService.platformType + '/flowchart']);
      }
    });
  }
}
