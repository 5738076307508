export const environment = {
  production: false,
  envName: 'staging',
  API_URL: 'https://api-staging-now.engineer.ai',
  STUDIO_API_URL: 'https://api-staging-builder.engineer.ai',
  TRACKER_API_URL: 'https://staging.api.tracker.engineer.ai',
  TRACKER_SECRET_KEY: 'PXIdJKzxCXowjXPIRgLm',
  STUDIO_URL: 'https://staging.engineer.ai',
  BUILDER_PAYMENT_URL: 'https://staging.engineer.ai/instant_prototype?build_card_id={buildCardId}',
  BUILDER_FEATURES_URL: 'https://staging.engineer.ai/features',
  USER_COOKIE: 'user_staging',
  GUIDLINE_TOOTIP_COOKIE: 'staging_guidline',
  ONBOARDING_COOKIE: 'staging_onboarding',
  VERSION0_TOOLTIP_COOKIE: 'staging_version0',
  enableSentry: true,
  PAYMENT_APP_TOKEN: '9ut4ECNZXKlh5oBKclp_MA',
  PAYMENT_URL : 'https://staging-payments.engineer.ai/',
  INTERCOM_APP_ID : 'ob3az46u',
  PM_DASHBOARD_AUTH:'tpdpdxTvmdfGFyDpWTaK',
  CALENDLY_URL : 'https://staging-scheduler.builder.ai/?call_type={type}&token=tpdpdxTvmdfGFyDpWTaK',
  PM_DASH_URL: 'https://api-staging-pmdashboard.engineer.ai/api/v1/',
  white_board_base_url: "https://www.whiteboard.team",
  whiteBoardClientId: "0a5513c6d360b46ec7c45aa045642f4c",
  whiteBoardClientSecret: "02466ba44b99bd7404b5d574f92bff48",
  nowUrl:'https://staging-now.engineer.ai'

};
