import { Injectable, HostListener } from '@angular/core';
import { PreviewModel } from '../models/preview.model';
import { Subject, Observable, BehaviorSubject, Subscription, ReplaySubject } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRefService } from '@core/window-ref.service';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import { DataService } from './data.service';
import { environment } from '@env/environment';
import { Angulartics2Segment } from 'angulartics2/segment';
import { AuthService } from '@core/auth.service';
import { CurrencyModel } from '../models/currency.model';
import { isArray, isObject } from 'rxjs/internal-compatibility';
import { pluck } from 'rxjs/internal/operators';
import { from } from 'rxjs/index';
import { PrototypeInstallmentModel } from '@shared/models/prototype-installment.model';
import { Intercom } from 'ng-intercom';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class DataCommService {
    rearrangeConfirmPopup = false;
    isFeatureUserUploaded = false;
    showIntercomSubject: Subject<any> = new Subject();
    showIntercom$ = this.showIntercomSubject.asObservable();
    featureListSubject: Subject<PreviewModel[]> = new Subject();
    featureList$ = this.featureListSubject.asObservable();
    dataErrorSubject: Subject<boolean> = new Subject();
    dataError$ = this.dataErrorSubject.asObservable();
    selectedAreaSubject: Subject<any> = new Subject();
    selectedArea$ = this.selectedAreaSubject.asObservable();
    displayMoreItemsSubject: Subject<any> = new Subject();
    displayMoreItems$ = this.displayMoreItemsSubject.asObservable();
    selectedHotspotSubject: Subject<any> = new Subject();
    selectedHotspot$ = this.selectedHotspotSubject.asObservable();
    private isSideKickAddedSource = new Subject<object>();
    isSidePanelClicked$ = this.isSideKickAddedSource.asObservable();
    fileCountSource = new Subject();
    fileCountSource$ = this.fileCountSource.asObservable();
    addIconsSource = new Subject();
    addIconsSource$ = this.addIconsSource.asObservable();
    addCarouselSource = new Subject();
    addCarouselSource$ = this.addCarouselSource.asObservable();
    iconListSource = new Subject();
    iconListSource$ = this.iconListSource.asObservable();
    addClickableItemSource = new Subject();
    addClickableItemSource$ = this.addClickableItemSource.asObservable();
    switchViewSource = new Subject();
    switchViewSource$ = this.switchViewSource.asObservable();
    isMobileDeviceSource: Subject<boolean> = new Subject();
    isMobileDevice$ = this.isMobileDeviceSource.asObservable();
    touchGestureSource: Subject<string> = new Subject();
    touchGesture$ = this.touchGestureSource.asObservable();
    mobOtherOptionsSource: Subject<string> = new Subject();
    mobOtherOptions$ = this.mobOtherOptionsSource.asObservable();
    industryTypeSource = new Subject();
    industryTypeSource$ = this.industryTypeSource.asObservable();

    sidekickImageClickSource: Subject<any> = new Subject();
    sidekickImageClicked$ = this.sidekickImageClickSource.asObservable();

    setSuggestionTootipPosSource: Subject<any> = new Subject();
    setSuggestionTootipPos$ = this.setSuggestionTootipPosSource.asObservable();

    suggestionTooltipClickedSource: Subject<any> = new Subject();
    suggestionTooltipClicked$ = this.suggestionTooltipClickedSource.asObservable();

    rejectSidekickRemovalSource: Subject<any> = new Subject();
    rejectSidekickRemoval$ = this.rejectSidekickRemovalSource.asObservable();

    cpeSavedSource: Subject<any> = new Subject();
    cpeSaved$ = this.cpeSavedSource.asObservable();

    syncDataSource: Subject<any> = new Subject();
    syncData$ = this.syncDataSource.asObservable();

    syncOverlaySource: Subject<any> = new Subject();
    syncOverlay$ = this.syncOverlaySource.asObservable();

    prototypeListSource: Subject<any> = new Subject();
    prototypeList$ = this.prototypeListSource.asObservable();

    bottomBarSource: Subject<any> = new Subject();
    bottomBar$ = this.bottomBarSource.asObservable();

    featureTitleSouce: Subject<any> = new Subject();
    featureTitleSouce$ = this.featureTitleSouce.asObservable();

    brandingForm: BehaviorSubject<string> = new BehaviorSubject('');

    logoSource = new Subject();
    logoSource$ = this.logoSource.asObservable();
    logoUpdateSource = new Subject();
    logoUpdateSource$ = this.logoUpdateSource.asObservable();
    findLogoTypeFeatureSource = new Subject();
    findLogoTypeFeatureSource$ = this.findLogoTypeFeatureSource.asObservable();
    loginSuccessSubject = new Subject();
    loginSuccess$ = this.loginSuccessSubject.asObservable();

    public undoList: any;
    public redoList: any;
    undoListSubject = new Subject();
    undoList$ = this.undoListSubject.asObservable();
    redoListSubject = new Subject();
    redoList$ = this.redoListSubject.asObservable();
    processLogoSubject = new Subject();
    processLogo$ = this.processLogoSubject.asObservable();
    headerPrototypeSubject = new Subject();

    industries;
    defaultIndustry;
    nowBuildCardId: string;
    featureList: PreviewModel[];
    buildcardId: string;
    featureUniqCode = '';
    projectName = '';
    customImageName = '';
    projectOwnerName = '';
    featureCount = 0;
    dataError = false;
    errorMsg = '';
    currentFrameIndex: number;
    landScapeMode = false;
    spotTypeForDestination: string;
    masterFeatureList: PreviewModel[];
    appReloaded = true;
    showShareModal = false;
    sharingUrl = '';
    public isContentChanged = false;
    public openConfirmBox = false;
    public confirmDeleteScreen = false;
    public deleteScreenOrFeature: string = '';
    Arr = Array;
    uploadedFilesCount = 0;
    public featureId: any;
    iconsList = [];
    public dashboardLoader = false;
    public platform = '';
    public platformType = '';
    public isMobileDevice: boolean;
    dashboardUrl = 'flowchart';
    heroImageId: string;
    prototypePaid = false;
    customPrototypePaid = false;
    grayStyle = false;
    buildcardPaid = false;
    isOwner = false;
    isGuestUser = false;
    public studioStoreTemplate: boolean = false;
    cpeSaveDisabled = false;
    designsApproved = false;
    isCurrentPrototypeApproved = false;
    userProfile: any;
    prototypeType = '';
    public underClientReview: any;
    // allScreensApproved = false;
    // allScreensRejected = false;
    showReviewedAllPopup = false;
    synOverlay = false;
    public isCpe = false;
    public updateInfo = true;
    public rippleEffect = false;
    public showTemplateBtn = false;
    showLogoPopup = false;
    eventPostLogin = null;
    isReplyPostLogin = null;
    scheduleCallWaiting = true;
    public scheduleCallSubscription: Subscription;
    public openTailorDropDown:boolean = false;
    public showRedoUndoToaster: boolean = false;  
    public screenEdited = [];

    public guidline = {
        viewAllHotspot: true,
        runPrototype: false,
        customisePrototype: false,
        sharePrototype: false,
        downloadPrototype: false,
        createHotspotArea: true,
        editHotspotArea: true,
        addNewScreen: false,
        showHideConnector: false,
        viewUnlinkedScreen: false,
        selectDestination: true,
        selectTransition: false,
        launchScreen: true,
        moreOptions: false,
        selectActionTypes: false
    };
    public onBoarding = {
        inProgressPrototype: false,
        inProgressEditMode: false,
        welcomeVideo: false,
        howItWorks: false,
        seeYourHotSopts: false,
        whatsEditMode: false,
        yourFlow: false,
        actionMenu: false,
        launchScreen: false,
        addExtraFeatures: false,
        areYouDone: false,
        onBoardingIconTooltipEditMode: false,
        onBoardingIconTooltipPrototype: false
    };
    public vertooltip = {
        viewCustom: true,
        commentsIcon: false,
        instantProtoype: false
    };
    public featureListMobile: PreviewModel[] = [];
    public betaPasswordChecked = false;
    public isPasswordProtected = false;
    public isPasswordProtectedBoxShown = false;
    public passwordProtected: boolean = false;
    showDownloader = false;
    downloadFormat;
    public showHideSuggToolTipOnEditFeature = false;
    public showHideContxtMenuOnEditFeature = false;
    childSwipeTriggered: boolean;
    actionTypeList: any;
    showCustomPrototypePopup = false;
    applicationId;
    public applicationName: string = '';
    isMasterTemplate: boolean;
    showHeroActionMenu = false;
    actionMenuDataObj;
    actionMenuPosition;
    checkMirrorOptions: boolean;
    featureTitleUpdated = false;
    rejectionCommentDetails = {
        featureData: null,
        show: false,
        comment: '',
        featureId: '',
        position: {}
    };
    overallApprovalPopupData = {
        confirmHeaderTxt: '',
        msg: '',
        confirmBtnTxt: '',
        pendingMode: ''
    };
    approvedFeatureCount = 0;
    rejectedFeatureCount = 0;
    customPrototypePrice: any;
    customPrototypeSysmbol: string;
    tailorMadePrice: any;
    tailorMadeSysmbol: string;
    prototypeList = [
        'Instant',
        'Custom'
    ];
    prototypeSelected = '';
    cmsUpdated = 0;
    userUpdated = 0;
    launchScreenObj: any;
    buildCardUniqueCode: string;
    isEditable: boolean;
    public featureVersion;
    public showAllFeaturesWindow = false;
    addedScreensCount = new Subject();
    addedScreensCount$ = this.addedScreensCount.asObservable();
    allFeaturesSource = new Subject();
    allFeaturesSource$ = this.allFeaturesSource.asObservable();
    public allFeatures: any;
    showUnlinkSlider = false;
    disableFeatureAdditionSource = new Subject();
    disableFeatureAdditionSource$ = this.disableFeatureAdditionSource.asObservable();
    showLoaderContent = false;
    public redNow = false;
    isRentalCardPaid = false;
    showRentalPaymentFlow = false;
    showRentalPaymentFlowSubject = new Subject();
    showRentalPaymentFlow$ = this.showRentalPaymentFlowSubject.asObservable();
    showCallBookedSuccess = false;
    paymentUrl = '';
    windowToCLose: any;
    paymentStatusInterval: any;
    private apiPollSub: Subscription;
    transactionId: string;
    proceedClickedFromRental = false;
    selectedSectionIndex = 0;
    newSignUpFlowScreen = 'signup';
    billingInfo = {
        name: '', firstname: '', lastname: '', email: '', contact: '', address: '', street: '', apartment: '', state: '', city: '',
        pincode: '', country: '', gstNumber: '', companyName: '', billing_entity: ''
    };
    buildCardData: any;
    prototypeInstallment: PrototypeInstallmentModel;
    urlSearchparams = {};
    showLoaderForPrototpeCreation = false;
    public trackerProjectId = null;
    // public showLoginPopup = false;
    showCalendar: boolean;
    screenTobeAppear = 'signup';
    public showBottomBar = false;
    showBrandingThankyouPopup = false;
    customiseYourPrototype = false;
    isCommentSigninSignUpForm = false;
    deletingMyFeature = false;
    popUpHeadings: any;
    touchPoint = '';
    touchPointHeading;
    touchPointSubHeading;
    showLogoutMessage = false;
    isUserClickedOnPreviewToPutComment = false;
    logoFeatureCount = 0;
    getUpdatedFeatuersData = {
        selectedFeatureId: null,
        updatedScreensArr: []
    };
    showHideReviewScreenPopup = false;
    featuresMaxVersion = 0;
    showFtrDesc = true;
    previewFrameIndex = 0;
    popupScreenOne = false;
    popupScreenTwo = false;
    featureIdForDetailPage;
    studioFeatureIdForSubFeatureComment;
    featureDetailReviewScreenCount = 0;
    //change for secret key
    manageCommentsMultiple = false;
    showFeedbackPopup :boolean = false;
    feedBackOptions:any;
    public undoRedoObj = {
        currentVersion: null,
        nextVersion: null,
        previousVersion: null
    }
    callbackForSharelink = false;
    callbackForDownLoadLink={
        isDownload:false,
        filetype:''
    }
    myMapping = [
        {
            isExist: true,
            platformType: 'mobile',
            comingSoon: false,
            selected: true,
            value: [
                { device: 'Android', comingSoon: false , selected: true, cpe: true},
                { device: 'IOS', comingSoon: false, selected: false, cpe: false},
                { device: 'Oculus', comingSoon: false , selected: false, cpe: false},
                { device: 'Mobile Site', comingSoon: false, selected: false , cpe: false},
            ]
        },
        {
            isExist: true,
            platformType: 'web',
            comingSoon: false,
            selected: false,
            value: [
                { device: 'Web', comingSoon: false , selected: false, cpe: true},
                { device: 'Windows', comingSoon: false, selected: false, cpe: false },
                { device: 'macOS', comingSoon: false, selected: false, cpe: false },
                { device: 'Windows Phone', comingSoon: true, selected: false, cpe: false }
            ]
        },
        {
            isExist: true,
            platformType: 'tablet',
            comingSoon: true,
            selected: false,
            value: [
                { device: 'Android', comingSoon: true , selected: false},
                { device: 'IOS', comingSoon: true , selected: false},
                { device: 'Windows Phone', comingSoon: true , selected: false }
            ]

        },
        {
            isExist: true,
            platformType: 'watch',
            comingSoon: false,
            selected: false,
            value: [
                { device: 'watchOS', comingSoon: false }
            ]

        }
    ]
    allPlatforms: any = [];
    selectedPlatforms: any = [];
    headerloop=[];
    currentPlatform:string;
    openNotification :boolean =false;
    allNotification:any;
    openComment: BehaviorSubject<string> = new BehaviorSubject(null);
    selectedNotification :any;
    isRouteChanged= false;
    resolvedCheckbox :boolean = false;
    showFilter:boolean =false;
    clonedComments = [];
    schedulerURL: SafeResourceUrl;
    previousIndex: any=null;
    openDropDownBoolean =false;
    clickedPlatformIndex: number;
    whiteboards:any;
    public undoListDisplay = false;
    public openedUndoRedo = false;
    public redoListDisplay = false;
    public brandingRequest: boolean;
    public platformComingSoon = false;
    public releasePopup: boolean = false;
    public maintenancePopup: boolean = false;
    public releaseNotes: any;
    public maintenancePopupMsg: any;
    public platformURL = "";
    public featureListMobileWithSub = [];
    previewStyleObj: any = {};
    previewPerfectScrollBarConfig: any = {
        suppressScrollX: true,
        suppressScrollY: true
    };
    previewScreenScrollPosition: any = {
        scrollLeft: 0,
        scrollTop: 0
    };
    reviewPerfectScrollBarConfig: any = {};
    leftReviewImageScale: number = 1;
    rightReviewImageScale: number = 1;
    reviewScreenLeftScrollPosition: any = {
        scrollLeft: 0,
        scrollTop: 0
    };
    reviewScreenRightScrollPosition: any = {
        scrollLeft: 0,
        scrollTop: 0
    };
    commentStyleObj: any = {};
    errorMsgFromApi ='No story for this feature';
    newErrorMsg ='Corresponding story is not linked to the screen';
    baseError = 'Something went wrong. Please try again!';
    public reviewScreenOpen: boolean = false;
    public carouselData: any = [];
    public isActivityModal: boolean = false;
    public activityItem: any;
    public isPreviewHorizontalScroll: boolean = false;
    previewHotspotHighlight: ReplaySubject<boolean> = new ReplaySubject();
    previewHotspotHighlight$: Observable<boolean> = this.previewHotspotHighlight.asObservable();
    showPreviewHotspotAnimation: boolean = true;
    switchHotspotOnDelete: Subject<boolean> = new Subject();
    disableRightPanel: boolean = false;
    automateVideoDownload: Subject<boolean> = new Subject();
    isVideoAnimation: boolean = false;
    unLinkedFeatures = [];
    finalArray = [];
    pushedUnlinkedFeaturesArr = [];
    isLargeWebFrame: boolean = false;
    public allScreenDeleted: boolean = false;
    public customPrototypeObj: any;
    public tailorMadePrototypeObj: any;
    public showViewUnlimitedVersion: boolean =  false;
    public fixedTaxPrice: any = 0;
    downLoadData =[];
    urls =[];
    prototypeVideoFeatures: string[] = [];
    prototypeVideoURL: string = '';
    currentOnBoardingStep: string = '';
    public isRedNow: boolean = false;
    public isProfessionalPrototype: boolean = false;
    deleteScreen = new Subject();
    userRoles: any[] = [];
    switchRoleSource: ReplaySubject<any> = new ReplaySubject(1);
    switchRole$: Observable<any> = this.switchRoleSource.asObservable();
    uploadImage = new Subject();
    public userDevice: string;
    public userDeviceOS: string;
    public userBrowser: string;
    public userUniqueid: string;
    public referrer: string = 'now';
    public videoHalfViewed: boolean = false;
    trackCustomerSignUp: ReplaySubject<string> = new ReplaySubject(1);
    signUpClickedFlag: boolean = false;
    trackCustomerRegSub: Subscription;
    closeAddButton: Subject<boolean> = new Subject();
    showOnboardingSubmenu : boolean = false;
    showLoaderGuestBuildCard: Subject<boolean> = new Subject();
    showLikePopup: boolean = false;
    userIPAddress: string = '';
    likePopupFlashTime: number;
    featureListMobileOrg: PreviewModel[] = [];
    featureListOrg: PreviewModel[] = [];
    isMainPlatformApiCalled: Subject<boolean> = new Subject();
    public showEmbedWaterMark: boolean = true;
    public guestWarningPopup: boolean = false;

    constructor(
        private dataService: DataService,
        private windowRef: WindowRefService,
        private cookieService: CookieService,
        private toasterService: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        public intercom: Intercom,
        public sanitizer: DomSanitizer,
        private analyticsSegment: Angulartics2Segment,
        private deviceService: DeviceDetectorService) {
        this.featureList = [];
        this.featureUniqCode = null;
        let isLandScape;
        windowRef.nativeWindow.addEventListener('orientationchange', (elem: any) => {
            // tslint:disable-next-line: deprecation
            if (windowRef.nativeWindow.orientation === -90 || windowRef.nativeWindow.orientation === 90) {
                isLandScape = true;
            } else {
                isLandScape = false;
            }
            
            this.landScapeMode = isLandScape;
            // if (elem.target.innerWidth >= 600 && isLandScape) {
            //     this.landScapeMode = false;
            // } else {
            //     if (isLandScape) {
            //         this.landScapeMode = true;
            //     } else {
            //         this.landScapeMode = false;
            //     }
            // }
        }, false);

        windowRef.nativeWindow.addEventListener('resize', (elem: any) => {
            const isMobile = this.checkIsMobileDevice();
            this.emitMobileDevice(isMobile);
        });
        const currentUrl = this.windowRef.nativeWindow.location.href;
        this.buildcardId = currentUrl.split('/')[3];
        this.sharingUrl = currentUrl.split('/').splice(-0, 4).join('/') + `${currentUrl.includes('v1') ? '/v1' : ''}/preview`;
        this.setDisplayMoreItemsSubject(false);
        // get guidline cookie from users browser to hide tooltip
        if (this.cookieService.getLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE)) {
            this.guidline = JSON.parse(this.cookieService.getLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE));
        }
        this.getOnboardingCookies();
    }

    getProjectName(): Observable<string> {
        return this.brandingForm.asObservable();
    }

    setProjectName(projectName: string) {
        this.brandingForm.next(projectName);
    }

    setPlatformDevice() {
        const urlParams = this.router.url.split(';');
        this.platform = urlParams[urlParams.length - 2].split('=')[1];
        this.platformType = urlParams[urlParams.length - 1].split('=')[1];
    }

    reloadData() {
        this.switchView(this.platform);
        this.dataService.getDataByBuildcardId(this.buildcardId, this.platform, this.platformType)
            .subscribe((data: any) => {
                this.successHandlr(data);
                if (this.redirectFromDashboard()) {
                    return false;
                }
            }, (error) => {
                this.errorHandlr(error);
            });
    }

    sidekickImageClicked(data: any) {
        this.sidekickImageClickSource.next(data);
    }
    suggestionTooltipClicked(data: any) {
        this.suggestionTooltipClickedSource.next(data);
    }

    rejectSidekickRemoval(data: any) {
        this.rejectSidekickRemovalSource.next(data);
    }

    cpeSavedFun(isRefreshed) {
        this.cpeSavedSource.next(isRefreshed);
    }

    loginSuccessFun(flag) {
        this.loginSuccessSubject.next(flag);
    }

    getUndoListFun(data) {
        this.undoListSubject.next(data);
    }

    getRedoListFun(data) {
        this.redoListSubject.next(data);
    }

    processLogoFun(event) {
        this.processLogoSubject.next(event);
    }

    undoRedoToastFun(data) {
        this.uploadImage.next(data);
    }

    emitMobileDevice(value: boolean) {
        if (this.isMobileDevice !== value) {
            this.isMobileDevice = value;
            this.isMobileDeviceSource.next(value);
        }
    }

    setUploadedFilesCount(fileCount: any) {
        this.fileCountSource.next(fileCount);
    }

    setAddedScreensCount(addRemoveObj) {
        this.addedScreensCount.next(addRemoveObj);
    }

    setFeatureList(data: any) {
        let defaultFound = false;
        if (data.features.length) {
            data.features.forEach((key, value) => {
                if (!defaultFound && data.features[value].is_launch_screen) {
                    defaultFound = true;
                } else {
                    data.features[value].is_launch_screen = false;
                }
            });
        }
        this.featureList = data.features.sort((a, b) => {
            if (a.is_launch_screen) {
                return -1;
            } else {
                return 0;
            }
        });
        const undoRedo = {
            previous_version: data.previous_version,
            next_version: data.next_version,
            current_version: data.current_version
        };
        if (data.previous_version >= 0 || data.next_version) {
            this.setUndoRedoValues(undoRedo);
        }
        this.featureList = [...data.features];
        this.setUpdatedFeaturesList(this.featureList);
        this.getMaxVersionFromCustomFeatures(this.featureList);
        this.featureListSubject.next(data);
        // this.setPreviewFrameIndex();
        this.getMaxFeatureVersion();
    }

    setUpdatedFeaturesList(featuresArr) {
        this.getUpdatedFeatuersData = {
            selectedFeatureId: '',
            updatedScreensArr: [],
        };
        featuresArr.forEach(feature => {
            if (feature.version >= 1 && feature.status === 'review') {
                this.getUpdatedFeatuersData.updatedScreensArr.push(feature.id);
            }
            if (feature.sub_features) {
                feature.sub_features.forEach((sfeature) => {
                    if (sfeature.version >= 1 && sfeature.status === 'review') {
                        this.getUpdatedFeatuersData.updatedScreensArr.push(feature.id + '>' + sfeature.id);
                    }
                });
            }
        });
    }

    getMaxVersionFromCustomFeatures(featuresArr) {
        const versionArr = [];
        featuresArr.forEach(feature => {
            versionArr.push(feature.version);
        });
        this.featuresMaxVersion = Math.max.apply(null, versionArr);
    }

    setDataError(isError: boolean) {
        this.dataError = isError;
        this.dataErrorSubject.next(isError);
    }

    setUndoRedoValues(data) {
        this.undoRedoObj.currentVersion = data.current_version;
        this.undoRedoObj.nextVersion = data.next_version;
        this.undoRedoObj.previousVersion = data.previous_version;  
    }

    successHandlr(data) {
        if (this.buildcardId === '574763' || this.buildcardId === '107873')
            this.dataService.getClientIP().subscribe((res: any) => this.userIPAddress = res.ip);
        this.getUserBrowserDevice();
        this.whiteboards = data.whiteboard_id;
        this.isOwner = data.is_owner;
        this.isGuestUser = data.guest_user;
        this.prototypePaid = data.prototype_payment_status;
        this.customPrototypePaid = data.custom_prototype_payment_status;
        this.grayStyle = data.gray_style ? true : false;
        this.buildcardPaid = data.is_running_card;
        this.buildCardUniqueCode = data.uniq_code;
        this.studioStoreTemplate = data.studio_store;
        this.isProfessionalPrototype = data.is_professional_prototype;
        if (this.authService.getLoggedInUser()) {
            if (this.isGuestUser) {
                this.assignGuestBuildCardToUser();                
            }
            this.fetchBuildCardData();
        }
        if(this.isGuestUser) {
            this.checkGuestWarningPopup();
        }
        this.isEditable = (data.is_editable === undefined) ? true : data.is_editable;
        this.setUndoRedoValues(data);
        if (data.tracker_identity) {
            this.trackerProjectId = data.tracker_identity;
        }
        if (!this.applicationId) {
            this.applicationId = data.application_id;
            this.applicationName = data.application_name;
        }
        this.isMasterTemplate = data.is_master_template;
        if (!this.isOwner) {
            this.disableCoachmarkForLoggedOutUser();
        }
        if (data.build_card_id) {
            this.nowBuildCardId = data.build_card_id;
            if (!this.router.url.includes('preview')) {
                this.getDashboardFeatureList(this.nowBuildCardId);
            }
        }
        this.bottomBarFun(this.grayStyle);
        if (data && data.features && data.features.length > 0) {
            this.setFeatureList(data);
            this.projectName = data.project_name;
            this.projectOwnerName = data.owner_name;
            this.isPasswordProtected = data.password_protected;
            this.featureCount = data.features_count;
            this.prototypeVideoFeatures = data.video_features;
            this.prototypeVideoURL = data.video_url;
        } else if (data && data.features && data.features.length === 0) {
            this.setFeatureList(data);
            this.errorMsg = Constants.noFeatureErrorMsg;
            this.projectName = data.project_name;
            this.projectOwnerName = data.owner_name;
            this.isPasswordProtected = data.password_protected;
            this.featureCount = data.features_count;
            // this.setDataError(true);
        }
        this.cpeSavedFun(true);
        this.underClientReview = data.under_client_review;
        // if (this.authService.userProfile.isClient) {
        //     this.userProfile = 'client';
        // }
        // if (this.authService.userProfile.isDesigner) {
        //     this.userProfile = 'designer';
        // }
        this.prototypeType = data.prototype_type;
        if (this.customPrototypePaid) {
            this.closeAllOnboarding();
        }
        if (data.prototype_list && data.prototype_list.length > 0) {
            // this.prototypeListFun(data.prototype_list);
            this.prototypeSelected = data.prototype_type;
        }
        this.designsApproved = data.prototype_approved;
        this.isCurrentPrototypeApproved = data.is_current_prototype_approved;
        if (!this.buildcardPaid) {
            this.customPrototypeAmount();
        }
        if (this.customPrototypePaid) {
            if (this.cookieService.getLocalStorage(environment.VERSION0_TOOLTIP_COOKIE)) {
                this.vertooltip = JSON.parse(this.cookieService.getLocalStorage(environment.VERSION0_TOOLTIP_COOKIE));
            }
        }
        if (data.undo_footsteps && data.undo_footsteps.length > 0) {
            this.undoList = data.undo_footsteps;
            this.getUndoListFun(data.undo_footsteps);
        }
        if (data.redo_footsteps && data.redo_footsteps.length > 0) {
            this.redoList = data.redo_footsteps;
            this.getRedoListFun(data.redo_footsteps);
        }
        if(this.isProfessionalPrototype) {
            this.headerPrototypeSubject.next(true);
        }
        if(this.isAirIndiaV1ProtoType()) {
            this.showEmbedWaterMark = false;
        }
    }

    bottomBarFun(flag) {
        if (flag) {
            this.fetchBuildCardData(flag);
        } else {
            this.bottomBarSource.next(flag);
        }
    }

    errorHandlr(err) {
        this.setDataError(true);
        if (err && err.error) {
            this.errorMsg = err.error.message;
        } else {
            this.toasterService.error(Constants.toasterMessage.ERROR);
        }
    }

    // setPreviewFrameIndex(index?: number) {
    //     if (index) {
    //         this.currentFrameIndex = index;
    //     }
    // }
    setFrameUrl(comp: string, urlSplitArray): string {
        const currentUrlArray = urlSplitArray;
        if (currentUrlArray.indexOf(comp) === -1) {
            currentUrlArray.push(comp);
        }
        if (this.featureUniqCode) {
            currentUrlArray.push(this.featureUniqCode);
        }
        return currentUrlArray.join('/');
    }

    setSelectedHotspot(hotspot: any) {
        this.spotTypeForDestination = hotspot.category;
        this.selectedHotspotSubject.next(hotspot);
    }

    getVisibleHotspotsCounts(hotspots: any[]) {
        let count = 0;
        if (hotspots && hotspots.length > 0) {
            hotspots.forEach(hotspot => {
                const items = hotspot.clickable_items;
                if (items && items.length > 0) {
                    count += items.length;
                }
            });
        }
        return count;
    }

    setSelectedArea(area: any) {
        this.selectedAreaSubject.next(area);
    }

    setDisplayMoreItemsSubject(value: boolean) {
        this.displayMoreItemsSubject.next(value);
    }

    sideKickAddFunc(customHotSpots) {
        this.isSideKickAddedSource.next(customHotSpots);
    }

    addIconFun(iconData) {
        this.addIconsSource.next(iconData);
    }

    addCarouselFun(carouselData) {
        this.addCarouselSource.next(carouselData);
    }

    updateIconList(iconList) {
        this.iconListSource.next(iconList);
        this.iconsList = iconList;
    }

    addClickableItemFun(clickbaleItem) {
        this.addClickableItemSource.next(clickbaleItem);
    }

    setSuggestionTootipPosFun(featureId) {
        this.setSuggestionTootipPosSource.next(featureId);
    }

    redirectFromDashboard(): boolean {
        let redirected = false;
        if (!this.isOwner && !this.isGuestUser && !this.authService.userProfile.isDesigner) {
            if (this.authService.cutsotmPrototypeVersion === null) {
                this.router.navigateByUrl(this.buildcardId + '/preview/' + this.platform + '/' + this.platformType);
            } else {
                this.router.navigateByUrl(this.buildcardId + '/' + this.authService.cutsotmPrototypeVersion + '/preview/' + this.platform + '/' + this.platformType);
            }
            redirected = true;
        } else {
            if (!this.prototypePaid && !this.buildcardPaid) {
                this.windowRef.nativeWindow.location = this.dataService.getBuilderPaymentUrl(this.buildcardId);
                redirected = true;
            } else {
                if (this.cookieService.getCookie('buildernow_visited') === null && !this.checkIsMobileDevice()) {
                    if (this.authService.cutsotmPrototypeVersion) {
                        this.cookieService.setCookie('buildernow_visited', 'true');
                        redirected = false;
                    }
                }
            }
        }
        return redirected;
    }

    redirectFromPreview(): boolean {
        let redirected = false;
        if (this.isOwner) {
            if (!this.prototypePaid && !this.buildcardPaid) {
                this.windowRef.nativeWindow.location = this.dataService.getBuilderPaymentUrl(this.buildcardId);
                redirected = true;
            } else if (this.cookieService.getCookie('buildernow_visited') === null && !this.checkIsMobileDevice()) {
                if (this.authService.cutsotmPrototypeVersion) {
                    this.cookieService.setCookie('buildernow_visited', 'true');
                    redirected = false;
                }
            }
        }
        return redirected;
    }

    switchView(value) {
        this.platform = value;
        this.switchViewSource.next(value);
    }

    checkIsMobileDevice(): boolean {
        return this.windowRef.nativeWindow.innerWidth <= 480;
    }

    mobOtherOptions(value: string) {
        this.mobOtherOptionsSource.next(value);
    }

    touchGesture(type: string) {
        this.touchGestureSource.next(type);
    }

    setGuidLineCookie(prevKey, nexKey) {
        this.guidline[prevKey] = false;
        this.guidline[nexKey] = true;
        // set guidline in cookie for the first time visited user
        this.cookieService.setLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE, JSON.stringify(this.guidline));
        this.showIntercomSubject.next();
    }

    isOnbordingDone() {
        const getOnboardingCookie = JSON.parse(this.cookieService.getLocalStorage(environment.ONBOARDING_COOKIE));
        if (!getOnboardingCookie.inProgressEditMode
            && !getOnboardingCookie.inProgressPrototype) {
            return true;
        } else {
            return false;
        }

    }

    setVersionTooltipCookie(prevKey, nexKey?) {
        this.vertooltip[prevKey] = false;
        if (nexKey) {
            this.vertooltip[nexKey] = true;
        }
        this.cookieService.setLocalStorage(environment.VERSION0_TOOLTIP_COOKIE, JSON.stringify(this.vertooltip));
    }

    disableCoachmarkForLoggedOutUser() {
        for (const guideLikeVal of Object.keys(this.guidline)) {
            this.guidline[guideLikeVal] = false;
        }
    }

    closeGuideLine(...args) {
        args.forEach(guidelinParams => {
            this.guidline[guidelinParams] = false;
        });
        this.cookieService.setLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE, JSON.stringify(this.guidline));
    }
    
    closeGuideLineAfterFiveSec() {
        setTimeout(() => {
            this.disableCoachmarkForLoggedOutUser();
        }, 5000);
    }

    industryTypeClicked() {
        this.industryTypeSource.next(true);
    }

    trackEvent(title: string, value?: any) {
        const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
        let userData;
        if (userCookieData) {
            this.cookieService.setAllCookie();
            userData = JSON.parse(userCookieData[1]);
        }
        let obj;
        const defaultParam = {
            'Build Card paid': (this.buildcardPaid) ? 'Y' : 'N',
            Mode: this.platform,
            User: (userData) ? 'Logged in' : 'Not logged in'
        };
        if (this.windowRef.nativeWindow.location.href.indexOf('flowchart') !== -1) {
            obj = { ...defaultParam, ...{ Dashboard: 'Flowchart' } };
        } else if (this.windowRef.nativeWindow.location.href.indexOf('gridview') !== -1) {
            obj = { ...defaultParam, ...{ Dashboard: 'Grid' } };
        } else {
            obj = defaultParam;
        }
        if (value) {
            obj = { ...obj, ...value };
        }
        this.analyticsSegment.eventTrack(title, obj);
    }

    trackSaveEvents(eventName, obj = {}) {
        const source = obj ? obj : { Source: this.sourcePage() };
        this.trackEvent(eventName, source);
    }
    sourcePage() {
        let source = '';
        if (this.router.url.includes('edit-screen')) {
            source = 'Edit hotspot';
        } else if (this.router.url.includes('add-hotspot')) {
            source = 'Add hotspot';
        } else if (this.router.url.includes('add-sidekick')) {
            source = 'Add sidekick';
        } else if (this.router.url.includes('add-icon')) {
            source = 'Add icon';
        } else if (this.router.url.includes('add-edit-carousel')) {
            source = 'Add carousel';
        }
        return source;
    }
    // getCurrencySymbol() {
    //     const userCookieData: object = this.cookieService.getCookie(environment.USER_COOKIE);
    //     const userData = JSON.parse(userCookieData[1]);
    //     if (userData.currency.symbol) {
    //         this.customPrototypeSysmbol =  decodeURIComponent(userData.currency.symbol);
    //     }
    // }

    syncDataFun(param) {
        this.syncDataSource.next(param);
    }

    syncOverlayFun(flag) {
        this.syncOverlaySource.next(flag);
    }

    prototypeListFun(data) {
        this.prototypeListSource.next(data);
    }

    syncData(frameData) {
        frameData.forEach((elem, key) => {
            if (elem.revised_on_cms === true) {
                this.cmsUpdated++;
            }
            if (elem.user_modified === true) {
                this.userUpdated++;
            }
        });
        if (this.cmsUpdated > 0) {
            this.synOverlay = true;
            const tcmsUpdated = this.cmsUpdated;
            const tuserUpdated = this.userUpdated;
            this.cmsUpdated = 0;
            this.userUpdated = 0;
            this.syncDataFun({ cmsUpdated: tcmsUpdated, userUpdated: tuserUpdated });
        }
    }

    getRejCommBoxPosition(event: any) {
        let left;
        let top;
        try {
            if (event.target.closest('.topblock').getBoundingClientRect().left >= 0) {
                left = Math.round(event.target.closest('.topblock').getBoundingClientRect().left);
            } else {
                left = event.screenX;
            }
            if (event.target.closest('.topblock').getBoundingClientRect().top >= 0) {
                top = Math.round(event.target.closest('.topblock').getBoundingClientRect().top);
            } else {
                top = event.screenY;
            }
        } catch (err) {
            left = event.screenX;
            top = event.screenY;
        }
        return {
            top: (top + 80),
            left: this.platform === 'mobile' ? (left - 20) :
                (left + ((event.target.closest('.topblock').getBoundingClientRect().width - 309) / 2))   //309 width of comment-box
        };
    }

    getMaxFeatureVersion() {
        this.featureVersion = 0;
        this.featureList.forEach(feature => {
            if (feature.version > this.featureVersion) {
                this.featureVersion = feature.version;
            }
        });
    }

    customPrototypeAmount() {
        if (!this.buildcardPaid) {
            this.dataService.customPrototypeAmount(this.buildcardId).subscribe((res: any) => {
                if(res.data && res.data.length > 0) {
                    this.tailorMadePrototypeObj = res.data.find(arrObj => arrObj.attributes.installment_type === 'tailor_made_prototype');
                    this.customPrototypeObj = res.data.find(arrObj => arrObj.attributes.installment_type === 'custom_prototype');

                    if (this.customPrototypeObj.attributes.installment_amount) {
                        this.customPrototypeSysmbol = this.customPrototypeObj.attributes.currency.data.attributes.symbol;
                        this.customPrototypePrice = (this.customPrototypeObj.attributes.installment_amount + this.customPrototypeObj.attributes.tax_amount).toFixed(2);
                    }
                    if (this.tailorMadePrototypeObj.attributes.installment_amount) {
                        this.tailorMadeSysmbol = this.tailorMadePrototypeObj.attributes.currency.data.attributes.symbol;
                        this.tailorMadePrice = (this.tailorMadePrototypeObj.attributes.installment_amount + this.tailorMadePrototypeObj.attributes.tax_amount).toFixed(2);
                    }
                }
            }, (error) => {
                // this.dataCommService.errorHandlr(error);
            });
        }
    }

    logoSourceFun(data) {
        this.logoSource.next(data);
    }
    logoUpdateFun(data) {
        this.logoUpdateSource.next(data);
    }
    saveUnlinkedFeatureInLocalStorage(unlinkFeatureList) {
        const getUnlinkFeatureData = JSON.parse(this.cookieService.getLocalStorage(Constants.rearrangeLocalStorageKeyName));
        if (getUnlinkFeatureData) {
            const mergeArray = [...unlinkFeatureList, ...getUnlinkFeatureData];
            this.cookieService.setLocalStorage(Constants.rearrangeLocalStorageKeyName, JSON.stringify([...new Set([...mergeArray])].reverse()));
        } else {
            this.cookieService.setLocalStorage(Constants.rearrangeLocalStorageKeyName, JSON.stringify(unlinkFeatureList));
        }
    }

    intercomHandlerBuildCard(unlinkFeatureList) {
        if (unlinkFeatureList && unlinkFeatureList.length > 0) {
            const buildCardId = this.buildcardId;
            const getIntercomShownforBuildCard = JSON.parse(this.cookieService.getLocalStorage(Constants.intercomHandlerStorageKeyName));
            if (getIntercomShownforBuildCard && getIntercomShownforBuildCard.length > 0) {
                let mergeArray = [];
                const findBuildCard = getIntercomShownforBuildCard.findIndex(buildCard => buildCard.id == buildCardId);
                if (findBuildCard === -1) {
                    mergeArray.push({ id: buildCardId, intercom: false });
                    const mergeFinal = [...getIntercomShownforBuildCard, ...mergeArray];
                    this.cookieService.setLocalStorage(Constants.intercomHandlerStorageKeyName, JSON.stringify([...new Set([...mergeFinal])].reverse()));
                }
            } else {
                let finalArray = [];
                finalArray.push({ id: buildCardId, intercom: false });
                this.cookieService.setLocalStorage(Constants.intercomHandlerStorageKeyName, JSON.stringify(finalArray));
            }
        }
    }

    unlinkFeatureFlag(flag) {
        const getUnlinkFeatureData = JSON.parse(this.cookieService.getLocalStorage(Constants.rearrangeLocalStorageKeyName));
        if (getUnlinkFeatureData) {
            getUnlinkFeatureData.sort((x, y) => {
                return (x === y) ? 0 : x ? -1 : 1;
            });
            const arr = getUnlinkFeatureData.sort().reverse();
            if (arr[0] === false || arr[0] === true) {
                arr[0] = flag;
            } else {
                arr.unshift(flag);
            }
            this.cookieService.setLocalStorage(Constants.rearrangeLocalStorageKeyName, JSON.stringify(arr));
        } else {
            if (getUnlinkFeatureData && getUnlinkFeatureData.length > 0) {
                getUnlinkFeatureData.unshift(flag);
            }
        }
        this.cookieService.setLocalStorage(Constants.rearrangeLocalStorageKeyName, JSON.stringify(getUnlinkFeatureData));
    }

    checkUnlinkFeatureIdInLocalStorage(unlinkFeatureList) {
        const getUnlinkFeatureData = JSON.parse(this.cookieService.getLocalStorage(Constants.rearrangeLocalStorageKeyName));
        const featureIds = [];
        if (getUnlinkFeatureData !== null) {
            unlinkFeatureList.forEach((item) => {
                const isFeatureIdExist = getUnlinkFeatureData.findIndex(arr => arr === item);
                if (isFeatureIdExist === -1) {
                    featureIds.push(item);
                }
            });
        }
        return featureIds;
    }
    getDashboardFeatureList(nowBuildCardId) {
        if (nowBuildCardId) {
            this.dataService.getDashboardFeatureLists(nowBuildCardId, this.platform, this.platformType).subscribe((res: any) => {
                this.allFeatures = res;
                this.getAllFeaturesSliderList(res);
            }, (err) => {
                this.toasterService.error('Something Went Wrong!');
            });
        }
    }
    getAllFeaturesSliderList(featureList) {
        this.allFeaturesSource.next(featureList);
    }

    disableFeatureAddition(setValue) {
        this.disableFeatureAdditionSource.next(setValue);
    }
    rearrangePopupTrack() {
        this.trackEvent('Re-link unlinked screen popup opened', '');
    }
    checkRedNow() {
        if (this.route.snapshot.queryParamMap.get('red') && this.route.snapshot.queryParamMap.get('red') === 'now') {
            this.redNow = true;
            this.cookieService.setCookie('buildernow_visited', 'true');
            this.router.navigateByUrl(this.buildcardId + `/preview/${this.platform}`);
        }
    }
    startPollingForPayment(isStop?) {
        if (isStop) {
            clearInterval(this.paymentStatusInterval);
        } else {
            this.paymentStatusInterval = setInterval(() => {
                if (this.apiPollSub) {
                    this.apiPollSub.unsubscribe();
                }
                this.apiPollSub = this.dataService.pollBuildCardStatus(this.buildcardId).subscribe(data => {
                    this.isRentalCardPaid = data['is_paid'];
                    if (this.isRentalCardPaid) {
                        this.transactionId = data['transaction_id'];
                        this.pollForSchedular(this.buildcardId);
                        this.customPrototypePaid = true;
                        clearInterval(this.paymentStatusInterval);
                        this.handleNextPrevForRentalFlow(4);
                        this.windowToCLose.close();
                    }
                }, error => {

                });
            }, 2000);
        }
    }
    pollForSchedular(buildCardId) {
        this.scheduleCallSubscription = this.dataService.scheduleCallPolling(buildCardId)
            .subscribe( (res: any) => {
                if ( res.data.status === 'pending') {
                    this.scheduleCallWaiting = true;
                    this.pollForSchedular(buildCardId);
                } else {
                    this.scheduleCallSubscription.unsubscribe();
                    this.scheduleCallWaiting = false;
                }
            });
    }

    handleNextPrevForRentalFlow(index?) {
        this.proceedClickedFromRental = false;
        index ? this.selectedSectionIndex = index : this.selectedSectionIndex++;
    }

    validateEmail(email) {
        if (!email) {
            return;
        }
        const rest = email.substring(0, email.lastIndexOf('@'));
        if (rest.includes('..') && (rest.includes('"')) && ((rest.charAt(0) !== '"') || (rest.charAt(rest.length - 1) !== '"'))) {
            return false;
        } else if (rest.includes('..') && (rest.includes('\'')) && ((rest.charAt(0) !== '\'') || (rest.charAt(rest.length - 1) !== '\''))) {
            return false;
        } else if (rest.includes('..') && !(/^'|"/.test(rest))) {
            return false;
        }
        if ((rest.charAt(0) === '.') || (rest.charAt(rest.length - 1) === '.')) {
            return false;
        }
        if (email.includes('@') && !(email.split('@')[1].split('.').length >= 2) && (email.split('@')[1].split('.')[email.split('@').length - 1] !== '')) {
            return false;
        }
        if (!rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/["(),:;<>@[\]]/.test(rest))) {
            return true;
        } else if (rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/[(),:;<>@[\]]/.test(rest))) {
            return true;
        } else if (((rest.charAt(1) === '.') || (rest.charAt(rest.length - 2) === '.')) && ((rest.charAt(0) === '"') && (rest.charAt(rest.length - 1) === '"'))) {
            return true;
        } else if (/^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)+$/.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    setQueryparams() {
        const path = window.location.href;
        if (!path.includes('?')) { return; }
        const params = {};
        path.substr(path.indexOf('?')).split('&').forEach(param => {
            const data = param.split('=');
            params[data[0]] = decodeURIComponent(data[1]);
        });
        this.urlSearchparams = params;
        return params;
    }

    getMappedModel<T>(modelToBeMapped: T, jsonObject: any): T {
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
                    const subObj = jsonObject[key]['data'];
                    if (isArray(subObj)) {
                        let tempArr = [];
                        from(subObj).pipe(pluck('attributes')).subscribe((item) => {
                            tempArr.push(item);
                        });
                        modelToBeMapped[key] = tempArr;
                    } else if (isObject(subObj)) {
                        if (key === 'currency') {
                            modelToBeMapped[key] = this.getMappedModel(new CurrencyModel(), subObj['attributes']);
                        } else {
                            modelToBeMapped[key] = subObj['attributes'];
                        }
                    }
                } else {
                    if (key !== 'available_country') {
                        modelToBeMapped[key] = jsonObject[key];
                    }
                }
            }
        }
        return modelToBeMapped;
    }

    fetchBuildCardData(flag?) {
        this.dataService.fetchSingleBuildCardData(this.buildCardUniqueCode).subscribe((data: any) => {
            this.buildCardData = data.data.attributes;
            if (this.buildCardData.is_branding_added) {
                this.bottomBarSource.next(false);
            } else if (flag) {
                this.bottomBarSource.next(true);
            }
        }, error => {

        });
    }

    showHidePaymentFlow(showHideVal) {
        this.showRentalPaymentFlow = showHideVal;
        this.showRentalPaymentFlowSubject.next(showHideVal);
    }

    getTouchPointHeadings(screenName) {
        if (['forgotPassword', 'resetPassword', 'resetPassword2'].includes(screenName)) {
            this.touchPointHeading = 'Forgot your';
            this.touchPointSubHeading = 'password?';
            if (screenName === 'resetPassword') {
                this.touchPointHeading = this.touchPointHeading.replace('Forgot', 'Reset');
            }
            if (screenName === 'resetPassword2') {
                this.touchPointHeading = 'Password';
                this.touchPointSubHeading = 'Changed';
            }
        } else {
            if (this.popUpHeadings && this.popUpHeadings[this.touchPoint] && this.popUpHeadings[this.touchPoint].screen_flows) {
                const dataToRefer = this.popUpHeadings[this.touchPoint].screen_flows;
                let dataFlag;
                switch (screenName) {
                    case 'checkEmail':
                        dataFlag = 'signup1';
                        break;
                    case 'signup':
                        dataFlag = 'signup2';
                        break;
                    case 'signin':
                        dataFlag = 'signin';
                        break;
                    default:
                        break;
                }
                if (!dataToRefer[dataFlag]) {
                    this.touchPointHeading = 'Sign up to get';
                    this.touchPointSubHeading = 'your prototype';
                } else {
                    this.touchPointHeading = dataToRefer[dataFlag].heading;
                    this.touchPointSubHeading = dataToRefer[dataFlag].sub_heading;
                }
            } else {
                this.touchPointHeading = 'Sign up to get';
                this.touchPointSubHeading = 'your prototype';
            }
        }
    }
    findScreenForEvent() {
        let screen = '';
        switch (this.touchPoint) {
            case Constants.touchPoints.previewHeader:
                screen = 'Builder Now Preview';
                break;
            case Constants.touchPoints.campaignLink:
                screen = 'Builder Now campaign';
                break;
            case Constants.touchPoints.putComment:
                screen = 'Builder Now Comments';
                break;
        }
        return screen;
    }

    public handleIntercom() {
        const intercomId = environment.INTERCOM_APP_ID;
        this.intercom.shutdown();
        this.intercom.boot({
            appId: intercomId,
            alignment: 'left',
            widget: {
                activator: '#intercom'
            },
            user_id: `${this.authService.userProfile.userData.first_name}_${this.authService.userProfile.userData.last_name}_builder_${
                this.authService.userProfile.userData.id}`

        });
        this.intercom.hide();
        // this.intercom.showNewMessage('Need help with my user flow, please help to finesse the prototype');
    }

    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    setOnBoardingCookie(currentStep, nextStep) {
        this.onBoarding[currentStep] = false;
        this.onBoarding[nextStep] = true;
        this.currentOnBoardingStep = nextStep;
        // set guidline in cookie for the first time visited user
        // this.cookieService.setLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE, JSON.stringify(this.guidline));
        this.cookieService.setLocalStorage(environment.ONBOARDING_COOKIE, JSON.stringify(this.onBoarding));
        if (currentStep === 'welcomeVideo' && nextStep === 'howItWorks') this.cookieService.setLocalStorage('prototypeFlag', '');
        //this.showIntercomSubject.next();
    }
    closeOnboarding(e, onboardingstep, openMode, skipTour=false) {
        e.stopPropagation();
        this.onBoarding[onboardingstep] = false;
        this.onBoarding['inProgress' + openMode] = false;
        this.onBoarding['onBoardingIconTooltip' + openMode] = false;
        if(onboardingstep === 'howItWorks' || onboardingstep=== 'seeYourHotSopts' || onboardingstep === 'whatsEditMode') {
            this.onBoarding['yourFlow'] = true;
        }

        if (skipTour) {
            this.trackEvent('skip_tour_clicked', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice
            });
        } else if (onboardingstep == 'howItWorks' && openMode == 'Prototype') {
            this.trackEvent('tooltip_screen_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                tooltip_close: 1
            });
        } else if (onboardingstep == 'seeYourHotSopts' && openMode == 'Prototype') {
            this.trackEvent('tooltip_screen_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                tooltip_close: 2
            });
        }  else if (onboardingstep == 'whatsEditMode' && openMode == 'Prototype') {
            this.trackEvent('tooltip_screen_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                tooltip_close: 3
            });
        }  else if (onboardingstep == 'yourFlow' && openMode == 'EditMode') {
            this.trackEvent('edit_guide_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                edit_guide_close: 1
            });
        } else if (onboardingstep == 'actionMenu' && openMode == 'EditMode'){
            this.trackEvent('edit_guide_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                edit_guide_close: 2
            });
        }  else if (onboardingstep == 'launchScreen' && openMode == 'EditMode') {
            this.trackEvent('edit_guide_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                edit_guide_close: 3
            });
        }  else if (onboardingstep == 'addExtraFeatures' && openMode == 'EditMode') {
            this.trackEvent('edit_guide_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                edit_guide_close: 4
            });
        } else if (onboardingstep == 'areYouDone' && openMode == 'EditMode') {
            this.trackEvent('edit_guide_closed', {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice,
                edit_guide_close: 5
            });
        }

        setTimeout(() => {
            this.onBoarding['onBoardingIconTooltip' + openMode] = false;
            this.cookieService.setLocalStorage(environment.ONBOARDING_COOKIE, JSON.stringify(this.onBoarding));
        }, 3000);
        this.cookieService.setLocalStorage(environment.ONBOARDING_COOKIE, JSON.stringify(this.onBoarding));
        this.maintainReleaseNote();
    }

    getOnboardingCookies() {
        if (this.cookieService.getLocalStorage(environment.ONBOARDING_COOKIE)) {
            this.onBoarding = JSON.parse(this.cookieService.getLocalStorage(environment.ONBOARDING_COOKIE));
        }
    }

    closeAllOnboarding() {
        for (const key in this.onBoarding) {
            this.onBoarding[key] = false;
        }
        this.cookieService.setLocalStorage(environment.ONBOARDING_COOKIE, JSON.stringify(this.onBoarding));
    }

    openClosePopup(value, laststep?) {
        this.showFeedbackPopup = value;
        if(laststep) {
            this.trackEvent('feedback_close_cta_clicked',  {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice
            });  
        }
        if (value) {
            this.trackEvent('feedback_screen_opened',  {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice
            });
        } else {
            this.trackEvent('feedback_screen_closed',  {
                user_id: this.userUniqueid,
                user_browser: this.userBrowser,
                user_device: this.userDevice
            });
            if (!this.router.url.includes('preview')) {
                window.location.reload();
            }
        }
        return false;
    }

    createMapping() {
        const headerLoop = [];
        let innerMapping: any = {title: '', values: []};
        const selectedPlatforms = this.selectedPlatforms;
        const mappedPlatforms = this.myMapping;
        mappedPlatforms.forEach(mappedPlatform => {
            if (mappedPlatform.isExist) {
                const values = mappedPlatform.value;
                values.forEach(devices => {
                    const device = selectedPlatforms.find(e => e.title == devices.device);
                    const duplicatedDevice = headerLoop.find(e => e.title == mappedPlatform.platformType);
                    if (device && !duplicatedDevice) {
                        innerMapping.title = mappedPlatform.platformType;
                        innerMapping.comingSoon = mappedPlatform.comingSoon;
                        innerMapping.selected = mappedPlatform.selected;
                        const object = Object.assign({}, device);
                        object.selected = false;
                        object.comingSoon = devices.comingSoon;
                        object.cpe = devices.cpe;
                        innerMapping.values.push(object);
                        headerLoop.push(innerMapping);
                        innerMapping = {title: '', values: []};
                    } else if (device && duplicatedDevice) {
                        headerLoop.forEach(element => {
                            if (element.title == mappedPlatform.platformType) {
                                const object = Object.assign({}, device);
                                object.comingSoon = devices.comingSoon;
                                object.selected = devices.selected;
                                object.cpe = devices.cpe;
                                element.values.push(object);
                            }
                        });
                    }
                });
            }
        });
        this.headerloop = headerLoop;
    }
    setPlatformVar() {
        const platform = this.platform;
        const platformType = this.platformType;
        this.headerloop.forEach(ele => ele.selected = false);
        const findDevice = this.headerloop.find(e => e.title == platform);
        if (findDevice) {
            findDevice.selected = false;
            const findPlatform = findDevice.values.find(e => e.title.replace(' ', '-').toLowerCase() == platformType.toLowerCase());
            if (findPlatform !== null && findPlatform !== undefined) {
                findPlatform.selected = true;
                findDevice.selected = true;
                if (findDevice.comingSoon === true || findPlatform.comingSoon === true) {
                    this.platformComingSoon = true;
                }
            } else {
                findPlatform.selected = false;
            }
        }
    }

    getNotificationValue(){
        return this.openComment.asObservable();
    }
    setNotificationValue(value) {
        this.openComment.next(value);
    }
    //for sub task name mapping
    notificationApi(build_card_id, platform, platformType, userEmail, page){
        this.dataService.getNotification(build_card_id, platform, platformType, userEmail, page).subscribe((res)=>{
            this.allNotification = res;
            this.allNotification.notifications.forEach(element => {
               this.featureList.forEach(element1 => {
                   if(element1.id  == element.build_card_feature_id ){
                       if(element.metadata.story.story_type == "sub_task"){
                           let subFeature;
                           if(element1.sub_features){
                              subFeature = element1.sub_features.find(e=>e.story_id == element.metadata.story.id);
                           }
                           if(subFeature){
                               element.parentFeature = subFeature.title;
                           }
                           else{
                            element.parentFeature = element1.title;
                           }
                       }
                       else{
                        element.parentFeature = element1.title;

                       }
                   }
               });
            });
           })
    }
    public showTimeKitWindow(isDemo, specingID, callType) {

        let params = '';
        const userDetails = this.authService.getLoggedInUser();
        if (userDetails) {
            const user = userDetails;
            params += user.first_name ? ('&name=' + user.first_name + (user.last_name ? (' ' + user.last_name) : '')) : '';
            params += user.email ? ('&email=' + user.email + '') : '';
            params += user.phone_number ? ('&phone=' + user.phone_number) : '';
            params += user.organisation ? ('&company=' + user.organisation) : '';
        }
        if (specingID && specingID > 0) {
            return (this.getSpecCalendlyLink(callType) + '&generic_id=' + specingID + params);
            // window.open(this.getSpecCalendlyLink(callType) + '&generic_id=' + specingID + params, '_blank', 'height=740,width=740');
        } else {
            return (this.getSpecCalendlyLink(callType) + '&generic_id');
            // window.open(this.getSpecCalendlyLink(callType) + '&generic_id', '_blank', 'height=740,width=740');
        }
    }
    public getSpecCalendlyLink(callType) {
        const typeOfCall = callType ? callType : 'spec';
        const url =  environment.CALENDLY_URL.replace('{type}', typeOfCall);
        return url;
    }
    closeFilter(){
        this.showFilter = false;
    }

    maintainReleaseNote() {
        let subAPI;
        const email = this.cookieService.getCookieEmail(environment.USER_COOKIE);
        if (email) {
            subAPI = this.releaseAndMaintenance(email);
        }
        if(subAPI) subAPI.unsubscribe();
    }

    releaseAndMaintenance(email) {
        this.dataService.getReleaseNotes(email).subscribe((res: any) => {
        if (Object.keys(res.release).length > 0) {                    
            this.releasePopup = true;
            this.releaseNotes = res.release;
        }
        if (Object.keys(res.scheduled_maintenance).length > 0) {                    
            this.maintenancePopup = true;
            this.maintenancePopupMsg = res.scheduled_maintenance.message;
        }   
        }, (err) => {
        });
    }

    genericMethodForNoStory(errors,storyId?){
        // No story present for ID: 3151714
        if(errors && errors.length > 0 && errors.length < 2){
         const error = errors[0];
         if(error == this.errorMsgFromApi || error == `No story present for ID: ${storyId}`){
             return this.newErrorMsg;
         }
         else{
             return this.baseError;
         }
        }
    }
    
    activityModalToggle(event, item) {
        event.stopPropagation()
        this.isActivityModal = !this.isActivityModal;
        this.activityItem = item;
    }

    swipe(evt, windowWidth) {
        this.previewPerfectScrollBarConfig = {
            suppressScrollX: true,
            suppressScrollY: true
        };
        setTimeout(() => {
            if (!this.childSwipeTriggered && windowWidth <= 1024) {
                this.touchGesture(evt.type);
            } else if (this.childSwipeTriggered) {
                this.childSwipeTriggered = false;
            }
        }, 0);
    }

    setPreviewHotspotHighlight(flag: boolean): void {
        this.previewHotspotHighlight.next(flag);
    }

    getPreviewHotspotHighlight(): Observable<boolean> {
        return this.previewHotspotHighlight$;
    }

    startAutomateVideoDownload(flag: boolean): void {
        this.automateVideoDownload.next(flag);
    }

    deleteScreenFun(sectionToDelete) {
        this.deleteScreen.next(sectionToDelete);
    }

    isFeatureExist(arrToSearch: any[], unicode) {
        let flag = true;
        for (const feature of arrToSearch) {
            const isIndex = feature.findIndex(arrObj => arrObj.uniq_code === unicode);
            if (isIndex !== -1) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    checkForDefaultScreen(features: any[]) {
        if (this.featureList.length > 0) {
            return features.findIndex(feature => {return feature.is_launch_screen;}) !== -1;
        } else {
            return true;
        }
    }

    searchAndMapUnlinkedFeatures(featureArrIndex, unlinkFeature, unlinkFeatureIndex) {
        if (this.finalArray[featureArrIndex + 1]) {
            if (this.isFeatureExist(
                this.finalArray, unlinkFeature.uniq_code
                )) {
                this.finalArray[featureArrIndex + 1].push(unlinkFeature);
                this.pushedUnlinkedFeaturesArr.push({
                    featureIndex: (featureArrIndex + 1),
                    feature: unlinkFeature
                });
                this.unLinkedFeatures.splice(unlinkFeatureIndex, 1);
                this.pushUnlikedMapping();
            }
        } else {
            if (this.isFeatureExist(
                this.finalArray, unlinkFeature.uniq_code
                )) {
                this.finalArray.push([]);
                this.finalArray[featureArrIndex + 1].push(unlinkFeature);
                this.pushedUnlinkedFeaturesArr.push({
                    featureIndex: (featureArrIndex + 1),
                    feature: unlinkFeature
                });
                this.unLinkedFeatures.splice(unlinkFeatureIndex, 1);
                this.pushUnlikedMapping();
            }
        }
    }

    pushUnlikedMapping() {
        if (this.unLinkedFeatures.length > 0) {
            this.unLinkedFeatures.forEach((unlinkFeature, unlinkFeatureIndex) => {
                if (unlinkFeature.hotspots.length > 0) {
                    unlinkFeature.hotspots.forEach((hotspot, hotspotIndex) => {
                        if (hotspot.clickable_items.length > 0) {
                            hotspot.clickable_items.forEach((item, itemIndex) => {
                                this.finalArray.forEach((featureArr, featureArrIndex) => {
                                    const findFeature = this.finalArray[featureArrIndex].findIndex(
                                        arrObj => arrObj.uniq_code === item.target_feature_uniq_code);
                                    if (findFeature !== -1) {
                                        this.searchAndMapUnlinkedFeatures(
                                            featureArrIndex,
                                            unlinkFeature,
                                            unlinkFeatureIndex
                                        );
                                    }
                                });
                            });
                        }
                    });
                }
                if (this.unLinkedFeatures.length === (unlinkFeatureIndex + 1)) {
                    this.pushNextLevelMapping();
                }
            });
        }
    }

    pushNextLevelMapping() {
        if (this.pushedUnlinkedFeaturesArr.length > 0) {
            this.pushedUnlinkedFeaturesArr.forEach((featureObj, index) => {
                if (featureObj.feature.hotspots.length > 0) {
                    featureObj.feature.hotspots.forEach((hotspot) => {
                        if (hotspot.clickable_items.length > 0) {
                            hotspot.clickable_items.forEach((item) => {
                                const findFeature = this.unLinkedFeatures.findIndex(arrObj => arrObj.uniq_code === item.target_feature_uniq_code);
                                if (findFeature !== -1 && this.finalArray[featureObj.featureIndex + 1]
                                    && this.isFeatureExist(this.finalArray, this.unLinkedFeatures[findFeature].uniq_code)) {
                                    this.finalArray[featureObj.featureIndex + 1].push(this.unLinkedFeatures[findFeature]);
                                    this.pushedUnlinkedFeaturesArr.push({
                                        feature: this.unLinkedFeatures[findFeature],
                                        featureIndex: (featureObj.featureIndex + 1)
                                    });
                                    this.unLinkedFeatures.splice(findFeature, 1);
                                    this.pushNextLevelMapping();
                                } else if (findFeature !== -1
                                    && this.isFeatureExist(this.finalArray, this.unLinkedFeatures[findFeature].uniq_code)) {
                                    this.finalArray.push([]);
                                    this.pushedUnlinkedFeaturesArr.push({
                                        feature: this.unLinkedFeatures[findFeature],
                                        featureIndex: (featureObj.featureIndex + 1)
                                    });
                                    this.finalArray[featureObj.featureIndex + 1].push(this.unLinkedFeatures[findFeature]);
                                    this.unLinkedFeatures.splice(findFeature, 1);
                                    this.pushNextLevelMapping();
                                }
                            });
                        }
                    });
                }
                if (this.pushedUnlinkedFeaturesArr.length === (index + 1)) {
                    this.pushedUnlinkedFeaturesArr = [];
                    this.pushUnlikedMapping();
                }
            });
        }
    }

    removeUnlickedFeature(featureCode) {
        const findIndex = this.unLinkedFeatures.findIndex(arrObj => arrObj.uniq_code === featureCode);
        if (findIndex !== -1) {
            this.unLinkedFeatures.splice(findIndex, 1);
        }
    }
    
    checkWhiteBoarding() {
        if (this.router.url.includes('whiteBoard')) {
            return true;
        }
        return false;
    }

    getPrototypeType() {
        let prototypeSelected: string = '';
        if (this.authService.cutsotmPrototypeVersion === 'v1') {
            prototypeSelected = 'Tailor-made';
            this.headerPrototypeSubject.asObservable().subscribe((isProfessional) => {
                prototypeSelected = isProfessional === true ? 'Professional' : 'Tailor-made';
            });
        } else {
            prototypeSelected = 'Instant';
        }
        return prototypeSelected;
    }

    undoRedoCommonFun(val, itemObj) {
        const payload = {
            'step_version': itemObj.version,
            'device' : itemObj.device,
            'platform': itemObj.platform
          }
        this.dataService.putUndoRedo(val, this.nowBuildCardId, payload).subscribe((data) => {
            let urlToRedirect = '';
            if (this.authService.cutsotmPrototypeVersion !== null) {
                urlToRedirect = this.buildcardId + '/v1/dashboard/' + this.platform +  '/' + this.platformType + '/flowchart';
            } else {
                urlToRedirect = this.buildcardId + '/dashboard/' + this.platform +  '/' + this.platformType + '/flowchart';
            }
            this.windowRef.nativeWindow.location.href = urlToRedirect;
        });
    }

    getUserBrowserDevice() {
        const deviceInfo = this.deviceService.getDeviceInfo();
        const userDetails = this.authService.getLoggedInUser();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        this.userBrowser = this.deviceService.browser;
        this.userDevice = isMobile ? 'mobile' : (isTablet ? 'tablet' : (isDesktopDevice ? 'desktop' : ''));
        this.userDeviceOS = this.deviceService.os;
        this.userUniqueid = userDetails ? userDetails.email : this.cookieService.getLocalStorage('_uetsid');
        
        if (document.referrer.includes('staging.engineer.ai') || document.referrer.includes('studio.builder.ai')) {
            this.referrer = 'studio';
        } else if (document.referrer.includes('builder.ai')) {
            this.referrer = 'builder.ai';
        }      
        // console.log('userBrowser:', this.userBrowser);
        // console.log('userDevice:', this.userDevice);
        // console.log('userDeviceOS:', this.userDeviceOS);
        // console.log('userUniqueid:', this.userUniqueid);
        // console.log('referrer: ', this.referrer);
    }

    demoVideoLoad() {
        let vid: any = document.getElementById('demoVideo');
        if (vid) {
            vid.onplay = () => {
                // console.log('inside video played');
                this.trackEvent('video_viewed', {
                    user_id: this.userUniqueid,
                    user_browser: this.userBrowser,
                    user_device: this.userDevice
                });
            };
           
            vid.ontimeupdate = () => {
                //console.log(vid.currentTime);
                if (vid.currentTime >= 90 && !this.videoHalfViewed) {
                    this.videoHalfViewed = true;
                    // console.log('video_half_viewed');
                    this.trackEvent('video_half_viewed', {
                        user_id: this.userUniqueid,
                        user_browser: this.userBrowser,
                        user_device: this.userDevice
                    });
                }
            };
    
            vid.onpause = () => {
                // console.log('inside video paused');
            };
            // vid.onended = () => {
            //     console.log('video ended');
            // };
        }
    }

    trackCustomerRegistration(user) {
        let payload = {
            registration: {
                name: user.first_name + ' ' + user.last_name,
                email: user.email,
                build_card_id: this.nowBuildCardId
            }
        };
        this.signUpClickedFlag = false;
        this.dataService.trackCustomerRegistration(payload).subscribe(_ => this.trackCustomerRegSub.unsubscribe());
    }

    getPlatformTypeText(platformType) {
        switch(platformType && platformType.toLowerCase()) {
            case 'ios': 
                platformType = 'iOS';
                break;
            case 'android': 
                platformType = 'Android';
                break;
            case 'web': 
                platformType = 'Web';
                break;
            case 'macos': 
                platformType = 'macOS';
                break;
            case 'windows': 
                platformType = 'Windows';
                break;
            case 'mobile-site': 
                platformType = 'Mobile Site';
                break;
            case 'watchos': 
                platformType = 'watchOS';
                break;
        }
        return platformType;
    }

    getAirIndiaCampaignEventData() {
        return {
            user_id: this.userUniqueid,
            device: this.platform,
            ip_address: this.userIPAddress,
            user_browser: this.userBrowser,
            user_device: this.userDevice
        };
    }

    checkWhiteBoard(){
        return this.router.url.includes('whiteBoard');
    }  
      
    assignGuestBuildCardToUser() {
        if(this.isGuestUser) {
            this.showLoaderGuestBuildCard.next(true);
            const guestBuildCardId = this.buildcardId;
            this.guestWarningPopup = false;
            this.dataService.assignToUser(guestBuildCardId).subscribe((res) => {
                this.cookieService.setLocalStorage('guestWarningPopup', '');
                if (this.applicationName === 'Create from Scratch') {
                    if (this.router.url.includes('/dashboard/')) {
                        window.location.reload();
                    } else {
                        this.router.navigate(['/' + this.buildcardId + '/dashboard/' + this.platform + '/' + this.platformType + '/flowchart']);
                    }                
                } else {
                    window.location.reload();
                }
            }, error => {
            this.showLoaderGuestBuildCard.next(false);
            this.toasterService.error(error.error);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            });
        }
    }
    
    isAirIndiaV1ProtoType() {
        return (this.authService.cutsotmPrototypeVersion === 'v1' && (this.buildcardId === '574763' || this.buildcardId === '107873')) ? true : false;
    }

    checkGuestWarningPopup() {
        if (this.cookieService.getLocalStorage('showUndoRedoToaster') === 'yes' || this.cookieService.getLocalStorage('guestWarningPopup') === 'yes') {
            setTimeout(() => {
                this.guestWarningPopup = true;
                this.cookieService.setLocalStorage('guestWarningPopup', 'yes');
            }, Constants.GUEST_WARNING_TIME);
        }
    }
}
